<template>
    <div :class="'hearBar ' + ($tool.isLowBrowser()?'hearBar-tip':'')">
        <div class="browser-tip" v-if="$tool.isLowBrowser()&&!lowBrowsesrTipClosed">
            此浏览器可能会影响部分冲呀功能，建议使用谷歌、Edge浏览器，国产双核浏览器（QQ、360、搜狗浏览器）使用极速模式。
            <i class="iconfont iconshurukuangshanchu-block" @click="closeLowBrowsesrTip"></i>
        </div>
        <div class="main-header">
            <a :href="`${baseHostname}/product`">
                <img src="./logo.png" width="92" height="28" />
            </a>
            <div class="header-link" style="margin-left: 56px">
                <a :href="`${baseHostname}/`" :class="isIndex?'active':''">首页</a>
            </div>
            <div class="header-link" style="margin-left: 32px">
                <a :href="`${baseHostname}/creator`" :class="isCreatorIndex?'active':''">创作中心</a>
            </div>
            <div class="header-link" style="margin-left: 32px">
                <a :href="`${baseHostname}/trend`" :class="isRec?'active':''">推荐</a>
            </div>
            <div class="main-search">
                <input
                    class="main-search-input"
                    placeholder="搜索创作者"
                    v-on:keyup.enter="search"
                    v-model="searchInput"
                />
                <span class="iconfont iconsousuo-line search-click" @click="search"></span>
            </div>
            <template v-if="showLogin">
                <div class="is-login-show" v-show="initialized && is_login">
                    <div class="floatRigth-box">
                        <div class="fb-div" @click="goPublish" v-if="userInfo.isCreator === 1">发布</div>
                        <a class="toBeCreater-div" :href="`${baseHostname}/creator?type=2`" v-else>成为创作者</a>
                        <div class="tx-text">
                            <a :href="`${baseHostname}/notifications`">
                            <span
                                class="iconfont iconxiaoxitixing-line"
                                style="font-size: 18px;margin-right: 6px"
                            ></span>提醒
                                <div class="tx-count" v-text="notifyCount>99?'99+':notifyCount" v-if="notifyCount>0"></div>
                            </a>
                        </div>
                        <div class="tx-text">
                            <a :href="`${baseHostname}/messages`">
                            <span
                                class="iconfont iconsixin-line"
                                style="font-size: 18px;margin-right: 6px;"
                            ></span>私信
                                <div class="tx-count" v-text="messageCount>99?'99+':messageCount" v-if="messageCount>0"></div>
                            </a>
                        </div>
                        <div class="avatar-login-user select-box-o" @click="showSelect">
                            <img :src="$tool.resizeCdnImage(userInfo.avator)" width="100%" />
                        </div>
                        <div class="nick-name-u select-box-o">
                        <span
                            @click="showSelect"
                            style="user-select: none;display: flex;align-items: center"
                        >
                            <!-- <span v-text="userInfo.nickname"></span> -->
                            <span
                                class="iconfont iconxiaoxiangxiajiantou-line"
                                style="font-size: 22px;margin-left: 4px;"
                            ></span>
                        </span>
                            <div class="select-box" v-show="select1show">
                                <div class="components-of-select">
                                    <a v-if="userInfo.isCreator === 1 && userInfo.creatorId" :href="`${baseHostname}/@${userInfo.creatorUri || userInfo.creatorId}`">创作者主页</a>
                                    <span @click="checkSelect(0)">个人主页</span>
                                    <span @click="checkSelect(1)">账号设置</span>
                                    <span @click="checkSelect(2)">支持订单</span>
                                    <span @click="checkSelect(3)">退出</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="regAndLog-div" v-show="initialized && !is_login">
                    <span @click="loginAndReg">登录</span>
                    <span style="margin-left: 32px;" @click="loginAndReg">注册</span>
                </div>
            </template>
        </div>
        <share></share>
        <confirm-box></confirm-box>
        <login :userInfo="userInfo" @flash="flash"></login>
    </div>
</template>

<script>
import login from "../login"
import confirmBox from "../confirmBox"
import share from "../share.vue"
import Soundwave from "../../lib/soundwave.js"

export default {
    components: {
        login,
        confirmBox,
        share,
    },

    props: {
        'baseHostname': {
            type: String,
            default: '',
        },
        'showLogin': {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            initialized: false,
            is_login: false,
            select1show: false,
            userInfo: {},
            searchInput: "",

            notifyCount: 0,
            messageCount: 0,

            isIndex: false,
            isCreatorIndex: false,
            isRec: false,
            lowBrowsesrTipClosed: false,
        }
    },

    created() {
        this.showLogin && this.getUserInfo()
    },

    mounted() {
        if (this.showLogin) {
            let select_box = document.querySelectorAll(".select-box-o")
            document.addEventListener("click", e => {
                if (
                    select_box[0].contains(e.target) ||
                    select_box[1].contains(e.target)
                ) {
                    //点击select
                } else {
                    //点击其他区域收齐
                    this.select1show = false
                }
            })

            window._user_info.then(() => {
                // 确认用户登录后再加载提醒
                this.getNotifyCount()
                this.updateUnreadCount()

                this.updateNotifyCountTimer = setInterval(() => {
                    this.getNotifyCount()
                    this.updateUnreadCount()
                }, 5 * 6e4) // 因为已经有长链更新消息数目，5分钟间隔就足够。作为一种长链更新的补充方案

                window.addEventListener("beforeunload", () => {
                    window.soundwave && window.soundwave.close()
                })
                this.initSoundwave()
            }).catch(() => {
            })
        }
    },

    destroyed() {
        clearInterval(this.updateNotifyCountTimer)
    },

    methods: {
        initSoundwave() {
            window.soundwave = new Soundwave({
                onmessage: msg => {
                    if (msg.type === "notice") {
                        clearTimeout(this.updateUnreadNotifyCountTimer)
                        this.updateUnreadNotifyCountTimer = setTimeout(() => {
                            this.getNotifyCount()
                            this.$emit("notifyUpdate", msg)
                        }, 2e3)
                    } else if (msg.type === "message") {
                        clearTimeout(this.updateUnreadMessageCountTimer)
                        this.updateUnreadMessageCountTimer = setTimeout(() => {
                            this.updateUnreadCount()
                            this.$emit("messageUpdate", msg)
                        }, 2e3)
                    }
                },
            }).connect()
        },

        showSelect() {
            this.select1show = !this.select1show
        },
        checkSelect(index) {
            let that = this
            this.select1show = false
            switch (index) {
                case 0:
                    window.location.href = `${this.baseHostname}/user/${this.userInfo.id}`
                    break
                case 1:
                    this.$modal.show("login2")
                    break
                case 2:
                    window.location.href = `${this.baseHostname}/orders`
                    break
                case 3:
                    this.$modal.show("confirm", {
                        data: {
                            title: " ",
                            content: "确认退出登录",
                            closeText: "取消",
                            confirmText: "确认",
                        },
                        confirm() {
                            this.$api
                                .logOut()
                                .then(({ data }) => {
                                    window.location.reload()
                                })
                                .catch((err) => {
                                    this.$toast(err.errmsg || '操作失败')
                                })
                        },
                    })
                    break
            }
        },
        loginAndReg() {
            this.$modal.show("login1")
        },
        getUserInfo() {
            let resolve = null
            let reject = null
            window._user_info = new Promise((resolve1, reject1) => {
                resolve = resolve1
                reject = reject1
            })
            return this.$api
                .userInfo()
                .then(({ data }) => {
                    data.nickname = data.profile.nickname
                    data.uid = data.profile.uid
                    data.avator = data.profile.avator
                    data.intro = data.profile.intro
                    this.userInfo = data
                    this.is_login = true

                    // 用户登录态，需要清除推广码。用户登录成功，则认为推广注册流程正确结束，清除推广码，防止残留
                    if (window.localStorage && window.localStorage.setItem) {
                        window.localStorage.setItem('promo-code', '')
                    }

                    this.$emit("userinfo", data)
                    resolve(data)
                })
                .catch(err => {
                    this.$emit("userinfo", { _login_: false })
                    reject(err)
                })
                .finally(() => {
                    this.initialized = true
                })
        },
        flash() {
            this.getUserInfo()
        },
        goPublish() {
            window.location.href = `${this.baseHostname}/update/add`
        },
        getNotifyCount() {
            this.$api
                .getNotifyCount()
                .then(({ data }) => {
                    this.notifyCount = data
                })
                .catch(err => {
                    // console.log(err.errmsg)
                })
        },
        search() {
            let s = this.searchInput
            if (s && s.trim()) {
                window.location.href = `${this.baseHostname}/search?q=` + encodeURIComponent(s.trim())
            }
        },
        updateUnreadCount() {
            this.$api.getMessageCount().then(({ data }) => {
                this.messageCount = data.count
            })
        },

        closeLowBrowsesrTip() {
            if (window.localStorage && window.localStorage.setItem) {
                this.lowBrowsesrTipClosed = true
                window.localStorage.setItem(
                    "low_browser_tip",
                    JSON.stringify({
                        close: 1,
                        expires: new Date().getTime() + 24 * 3600 * 1000,
                    })
                )
                document.body.className += " hide-low-browser-tip"
            }
        },
    },

    computed: {
        allNotifyCount() {
            return this.notifyCount + this.messageCount
        },
    },

    watch: {
        allNotifyCount() {
            if (this.allNotifyCount > 0) {
                const currTitle = document.getElementsByTagName("title")[0].innerText
                if (!this.originTitle || this.latestTitle != currTitle) {
                    this.originTitle = currTitle
                }
                this.latestTitle = `(${this.allNotifyCount}条消息) ${this.originTitle}`
                document.getElementsByTagName("title")[0].innerText = this.latestTitle
            } else if (this.originTitle) {
                document.getElementsByTagName("title")[0].innerText = this.originTitle
                this.originTitle = null
            }
        },
    },
}
</script>

<style lang="less">
@import url("../../lib/general");

.hide-low-browser-tip .hearBar,
.hide-low-browser-tip .hearBar.hearBar-tip {
    height: 80px;
}

.hearBar {
    min-width: 1056px;
    width: 100%;
    height: 80px;
    background-color: #fff;

    &.hearBar-tip {
        height: 140px;
    }

    .browser-tip {
        display: flex;
        justify-content: center;
        padding: 16px;
        height: 60px;
        text-align: center;
        font-size: 14px;
        line-height: 28px;
        color: #666;
        background: rgb(244, 244, 244);

        .iconshurukuangshanchu-block {
            font-size: 26px;
            top: 0;
            margin: 0;
            cursor: pointer;
        }
    }

    a {
        color: #777;

        &:visited {
            color: #777;
        }

        &:hover {
            color: #000;
        }
    }

    .main-header {
        margin: 0 auto;
        width: 1056px;
        display: flex;
        align-items: center;
        height: 80px;
    }

    .main-search {
        height: 32px;
        background-color: rgba(216, 216, 216, 0.26);
        margin-left: 32px;
        border-radius: 20px;
        overflow: hidden;
        padding-right: 16px;
        vertical-align: middle;

        .main-search-input {
            background-color: transparent;
            margin-left: 16px;
            width: 140px;
            font-size: 14px;
            height: 100%;
            border: 0;
            display: inline-block;
            outline: none;
            box-sizing: border-box;

            &::-webkit-input-placeholder {
                color: #d7d7d7;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: #d7d7d7;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #d7d7d7;
            }

            &:-ms-input-placeholder {
                color: #d7d7d7;
            }
        }

        .search-click {
            .link-color-normal();
            position: relative;
            top: 2px;
            margin-right: 0;
            font-size: 18px;
            color: #a7a7a7;
            cursor: pointer;
        }
    }

    .regAndLog-div {
        color: #777;
        flex: 1;
        text-align: right;

        span {
            .link-color-gray1();
            cursor: pointer;
        }
    }
}

.header-link {
    font-size: 16px;

    a.active {
        color: #000;

        &:hover,
        &:visited {
            color: #000;
        }
    }
}

/*已登录状态显示的div*/
.is-login-show {
    flex: 1;

    .tx-text {
        color: #888;
        margin-left: 32px;
        position: relative;
        cursor: pointer;

        .tx-count {
            position: absolute;
            background-color: #f37c07;
            color: white;
            left: 44px;
            top: -7px;
            font-size: 12px;
            padding: 0px 4px;
            border-radius: 20px;
            border: 2px solid #fff;
            line-height: 16px;
            min-width: 20px;
            text-align: center;
        }
    }

    .fb-div {
        .btn-color-orange1();
        width: 64px;
        height: 32px;
        background: #f37c07;
        border-radius: 16px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .toBeCreater-div {
        .btn-color-orange1();
        display: block;
        width: 107px;
        height: 32px;
        border-radius: 16px;
        line-height: 32px;
        font-size: 14px;
        text-align: center;
        background-color: #f37c07;
        cursor: pointer;
    }

    .avatar-login-user {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        overflow: hidden;
        margin-left: 32px;
        cursor: pointer;
    }

    .floatRigth-box {
        float: right;
        display: flex;
        align-items: center;
    }

    .nick-name-u {
        color: #888;
        margin-left: 8px;
        cursor: pointer;
        position: relative;

        > span {
            .link-color-normal();
        }

        .select-box {
            position: absolute;
            right: 0;
            top: 52px;
            z-index: 999;
        }
    }
}

.components-of-select {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    width: 150px;
    background-color: white;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 100;

    a, span {
        .link-color-gray3();
        padding-left: 24px;
        padding-right: 70px;
        display: block;
        padding-top: 13px;
        padding-bottom: 13px;
        color: #333333;
        word-break: keep-all;

        &:hover {
            background: #fcdc2c;
        }
    }
}

.fanzhuan {
    transform: rotateX(180deg);
}

.sanjiao {
    width: 0;
    height: 0;
    border-width: 0 30px 30px;
    border-style: solid;
    border-color: transparent transparent #fff; /*透明 透明  灰*/
    position: relative;
}
</style>
