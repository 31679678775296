import request from '../request'

let { get, post, deletes, put, patch } = request('https://api.chongya.com/v1')

let req = {
  getCheckinStatus(cid) {
    return get(`/checkin/${cid}/status`)
  },

  getCheckinCalendar(cid) {
    return get(`/checkin/${cid}/calendar`)
  },

  getCheckinCalendarOfMonth(cid, yearMonth) {
    return get(`/checkin/${cid}/calendar/${yearMonth}`)
  },

  signCheckin({ mood, content, visible }) {
    return put('/checkin/signin', { mood, content, visible })
  },

  updateCheckin(date, { visible }) {
    return patch(`/checkin/signin/${date}`, { visible })
  },

};

export default req
