<template>
    <div class="upload-avatar">
        <file-upload
            ref="upload"
            :headers="reqHeaders"
            v-model="files"
            :post-action="fileUri"
            name="file_path"
            :size="size"
            extensions="png,jpg,jpeg,gif,webp"
            accept="image/png,image/jpeg,image/gif,image/webp"
            :drop="!edit"
            @input-filter="inputFilter"
            @input-file="inputFile">
            <slot>
            </slot>
        </file-upload>

        <modal :ref="cropperName" :name="cropperName"
               transition="no-transition" overlayTransition="no-transition"
               :clickToClose="false" :width="cropperWidth" height="auto"
               :resizable="true" :reset="true" :scrollable="true"
               @before-open="beforeCropperOpen" @opened="onCropperOpened" @closed="afterCropperClosed">

            <div class="tc">
                <div class="avatar-edit">
                    <div class="avatar-edit-image">
                        <img ref="editImage" :src="typeof1.url" class="image-edit-img" />
                    </div>
                    <div class="text-center p-4">
                        <button type="button" class="btn btn-secondary1" @click.prevent="hideCropper">取消</button>
                        <button type="submit" class="btn btn-primary1" @click.prevent="editSave">确认</button>
                    </div>
                    <div class="mask" v-show="isUploading">
                        <div>上传中...</div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Cropper from '@chongya/cropperjs'
import '@chongya/cropperjs/dist/cropper.css'
import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload,
  },
  name: 'cropper',

  props: {
    cropperName: {
      type: String,
      default: 'cropper'
    },
    bl: {
      type: Number,
      default: 3 / 1
    },
    up: {
      type: String,
      default: '/upload/account/avatar'
    },
    size: {
      type: Number,
      default: 1024 * 1024 * 10,
    },
    closeto: {
      type: String
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    croppedCanvasMaxWidth: {
      type: Number,
      default: 1600,
    },
    croppedCanvasMaxHeight: {
      type: Number,
      default: 1200,
    },
    formFileParamName: {
      type: String,
      default: 'file'
    }
  },

  data() {
    return {
      files: [],
      edit: false,
      typeof1: {},
      reqHeaders: {},

      isUploading: false,

      cropperWidth: 500,
      cropperHeight: 400,
      cropperDefaultWidth: 500,
      cropperDefaultHeight: 400,
      cropperMinWidth: 400,
      cropperMinHeight: 300,
      cropperMaxWidth: 800,
      cropperMaxHeight: 800,
      cropperExtremeWidth: 200,

      isMayUpload: true,
      triggerCancel: false,

      fileUri: '',
    }
  },

  watch: {},

  created() {
    this.fileUri = this.$api.base_file_uri() + this.up
    this.$tool.isAbroadIP().then((data) => {
      if (data) {
        this.fileUri = this.$api.base_file_uri() + this.up
      }
    })

    this.reqHeaders = this.$api.getHeaders()

    // polyfill toblob
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function(callback, type, quality) {

          var binStr = atob(this.toDataURL(type, quality).split(',')[1]),
            len = binStr.length,
            arr = new Uint8Array(len);

          for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || 'image/png' }));
        }
      });
    }

  },
  methods: {
    editSave() {
      if (!this.isMayUpload) {
        return
      }
      this.triggerCancel = false
      try {
        this.isUploading = true
        this.edit = false
        this.isMayUpload = false
        let x = this.cropper.getCroppedCanvas({
          imageSmoothingQuality: 'high',
          maxWidth: this.croppedCanvasMaxWidth,
          maxHeight: this.croppedCanvasMaxHeight,
        })

        x.toBlob((blob) => {
          if (blob.size > this.size) {
            this.isUploading = false
            this.$toast('上传文件超出大小限制')
            return
          }

          var fd = new FormData();
          fd.append(this.formFileParamName, blob);//fileData为自定义

          const { params } = this.$props
          Object.keys(params).forEach(k => {
            fd.append(k, params[k])
          }) //暂支持单层对象

          var xmlHttp = new XMLHttpRequest();
          xmlHttp.withCredentials = true
          xmlHttp.open("POST", this.$api.base_file_uri() + this.up);
          for (let o in this.reqHeaders) {
            xmlHttp.setRequestHeader(o, this.reqHeaders[o])
          }
          xmlHttp.send(fd)
          xmlHttp.onreadystatechange = () => {
            this.isMayUpload = true
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
              let resp = JSON.parse(xmlHttp.response)
              // `triggerCancel`为true时用户触发了取消则不再返回成功
              !this.triggerCancel && this.$emit('cropperData', resp.data)
              this.isUploading = false
              this.hideCropper()
            } else if (xmlHttp.readyState === 4) {
              this.isUploading = false
              try {
                let resp = JSON.parse(xmlHttp.response)
                this.$toast(resp.errmsg || '上传图片失败')
              } catch {
                this.$toast('上传图片失败')
              }
            }
          }

        })
      } catch (e) {
        this.isMayUpload = true
        this.hideCropper()
        if (e.message) {
          this.$toast('出现异常：' + e.message)
        } else {
          this.$toast('出现异常：' + e)
        }

      }
    },
    alert(message) {
      // console.log(message)
    },
    inputFile(newFile, oldFile, prevent) {
      // 如果 newFile 值为 undefined 则是删除
      // 如果 oldFile 值为 undefined 则是添加
      // 如果 newFile, oldFile 都存在则是更新
      let that = this
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true
          this.showCropper({
            url: newFile.url,
            bl: that.bl
          })
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
      if (newFile && oldFile) {
        // 更新文件
        if (newFile.success !== oldFile.success) {   // 上传成功
          this.hideCropper()
          this.$emit('cropperData', newFile.response.data)
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      // 如果 newFile 值为 undefined 则是删除
      // 如果 oldFile 值为 undefined 则是添加
      // 如果 newFile, oldFile 都存在则是更新
      if (newFile && newFile.xhr && !newFile.xhr.withCredentials) {
        newFile.xhr.withCredentials = true
      }
      if (newFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('请选择一张图片')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    showCropper({ url, bl, file }) {
      if (this.closeto) {
        this.$modal.hide(this.closeto)
      }
      this.$modal.show(this.cropperName, { forceShow: true })
      this.typeof1 = { url, bl }
    },

    beforeCropperOpen(evt) {
      // 这里这么做是因为 `cropper` 模态窗开启了 `:resizable="true"`，
      // 当浏览器窗口`resize`时，会触发 `cropper` 的resize操作，即使`cropper`是隐藏状态，也会被`show`出来。
      // 所以 `evt.stop()` 是为了让 `cropper` 在发生 `resize` 时，阻塞不被显示。
      // 只有在意图明确的场景下，才展示`cropper`，也就是 `forceShow` 参数的作用，指明需要展示`cropper`模态窗。
      if (!evt.params.forceShow) {
        evt.stop()
      }
    },

    onCropperOpened() {
      let _this = this
      this.isUploading = false
      this.$nextTick(function() {
        if (!_this.$refs.editImage) {
          return
        }
        let cropper = new Cropper(_this.$refs.editImage, {
          aspectRatio: _this.typeof1.bl,
          autoCropArea: 1,
          viewMode: 3,
          crop(event) {
            // 由于 `Cropper` 的 `crop` 事件会被多次触发。
            // 设置 `_this.initCropperResize` 的目的是为了让 cropper resize计算逻辑只被执行一次。
            // 多次执行，会导致 `cropper` 模态窗被重复resize
            if (_this.initCropperResize) {
              return
            }
            _this.initCropperResize = true

            // console.log('crop event', event)
            let nw = event.target.naturalWidth
            let nh = event.target.naturalHeight
            let w = nw
            let h = nh

            if (nw < _this.cropperDefaultWidth && nh < _this.cropperDefaultHeight) {
              return
            }

            if (nw > nh) {
              if (nw < _this.cropperMinWidth && nh < _this.cropperMinHeight) {
                h = _this.cropperMinHeight
                w = h * nw / nh
              } else {
                if (nw > _this.cropperMaxWidth) {
                  w = _this.cropperMaxWidth
                  h = w * nh / nw
                } else if (nw < _this.cropperMinWidth) {
                  w = _this.cropperMinWidth
                  h = w * nh / nw
                }
                if (h > _this.cropperMaxHeight) {
                  h = _this.cropperMaxHeight
                  w = h * nw / nh
                }
              }
            } else {
              if (nw < _this.cropperMinWidth && nh < _this.cropperMinHeight) {
                w = _this.cropperMinWidth
                h = w * nh / nw
              }
              if (nh > _this.cropperMaxHeight) {
                h = _this.cropperMaxHeight
                w = h * nw / nh
              } else if (nh < _this.cropperMinHeight) {
                h = _this.cropperMinHeight
                w = h * nw / nh
              }
              if (w > _this.cropperMaxWidth) {
                w = _this.cropperMaxWidth
                h = w * nh / nw
              }
            }

            // width极限最小宽度
            if (w < _this.cropperExtremeWidth) {
              w = _this.cropperExtremeWidth
              h = w * nh / nw
            }

            w += 48
            h += 88

            _this.cropperWidth = w
            _this.cropperHeight = h
            // cropper.setCropBoxData({
            //   width: w,
            //   height: h,
            // })
            cropper.setCanvasData({
              width: w,
              height: h,
            })
            // console.log('newsize:', w, h)
            _this.$modal.hide(_this.cropperName)
            setTimeout(() => {
              _this.$modal.show(_this.cropperName, { forceShow: true })
            }, 500)

          }
        })

        _this.cropper = cropper
        // console.log('cropper open', cropper.getCanvasData())
      })
    },

    afterCropperClosed() {
    },

    hideCropper() {
      this.$refs.upload.clear
      this.typeof1 = {}
      this.cropperWidth = this.cropperDefaultWidth
      this.cropperHeight = this.cropperDefaultHeight
      this.initCropperResize = false
      this.triggerCancel = true
      this.cropper && this.cropper.destroy();
      this.cropper = null;
      this.$modal.hide(this.cropperName)
      if (this.closeto) {
        this.$modal.show(this.closeto)
      }
    }
  }
}
</script>

<style lang="less">
@import url("../lib/general");

.upload-avatar .avatar-upload .rounded-circle {
    width: 200px;
}

.upload-avatar .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: .6;
    text-align: center;
    background: #000;
}

.upload-avatar .avatar-edit {
    position: relative;
    padding: 24px;

    .text-center {
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }

    .avatar-edit-image {
        max-width: 100%;
        box-sizing: border-box;
    }

    .image-edit-img {
        width: 100%;
    }

    .btn-primary1 {
        .btn-color-normal();
        border: 1px solid #fcdc2c;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #000;
        background: #fcdc2c;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 12px;
        margin-left: 24px;
    }

    .btn {
        outline: 0;
        cursor: pointer;
    }

    .btn-secondary1 {
        .btn-color-gray1();
        border: 1px solid #BBBBBB;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #666666;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 12px;
    }

    .mask {
        position: absolute;
        top: 24px;
        bottom: 64px;
        left: 24px;
        right: 24px;
        display: flex;
        .middle-all();
        background-color: rgba(0, 0, 0, .5);

        > div {
            text-align: center;
            color: #fff;
        }
    }
}
</style>
