import request from '../request'

let baseFileUri = 'https://file.chongya.com/v1';
let { get, post } = request(baseFileUri)

let req = {
    base_file_uri: () => {
        return baseFileUri
    },

    uploadCommon: '/upload/image',

    uploadAlbumCover: '/upload/album/cover',

    uploadAccountAvatar: '/upload/account/avatar',

    uploadAccountBg: '/upload/account/bg-image',

    uploadArticleImage: '/upload/article/image',

    uploadArticleAudio: '/upload/article/audio',

    uploadArticleVideo: '/upload/article/video',

    uploadArticleCover: '/upload/article/cover',

    uploadArticleAttachment: '/upload/article/attachment',

    uploadArticleEditImage: '/upload/article/edit-image',

    uploadChatImage: '/upload/chat/image',

    uploadRemoteImage(url) {
        return post('/upload/article/external-image', { url })
    },

    uploadCommonRemoteImage(url) {
        return post('/upload/external-image', { url })
    },

    getStsToken() {
        return get('/sts/token')
    },

    saveOssFile(data) {
        return post('/sts/oss-file', data)
    },

    checkFileSHA1(sha1, fileType) {
        return get(`/sts/sha1/${fileType}/${sha1}`)
    },

};

export default req

export function setBaseFileUri(uri) {
    if (uri) {
        baseFileUri = uri
        let { get: _get, post: _post } = request(baseFileUri)
        get = _get
        post = _post
    }
}
