import request from '../request'

let { get, post, put } = request('https://api.chongya.com/v2')

let req = {

    sendsmscaptchaV2(mobile, opts = {}) {
        return post('/sendsmscaptcha', { mobile, ...opts })
    },
    accountSmsSendV2(mobile, opts = {}) {
        return post('/account/sendsmscaptcha', { mobile, ...opts })
    },

}

export default req
