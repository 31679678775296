function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

let guid = ''

try {
  const ma = document.cookie.match(/GUID=([^;]+)(;|$)/)
  if (ma && ma[1]) {
    guid = decodeURIComponent(ma[1])
  }
} catch (e) {
}

if (!guid) {
  guid = uuidv4().replace(/\-/g, '')
  let expires = new Date()
  expires.setTime(expires.getTime() + 10 * 365 * 24 * 3600 * 1000)
  document.cookie = `GUID=${encodeURIComponent(guid)}; expires=${expires.toUTCString()}; domain=chongya.com; path=/`;
}


const qs = (function () {
  var decode = decodeURIComponent;
  let str = location.search || ''
  if (str.length > 1) { // 去掉`?`问号
    str = str.substr(1)
  }
  return str
    .replace(/\+/g, ' ')
    .split('&')
    .filter(Boolean)
    .reduce(function (obj, item, index) {
      var ref = item.split('=');
      var key = decode(ref[0] || '');
      var val = decode(ref[1] || '');
      var prev = obj[key];
      obj[key] = prev === undefined ? val : [].concat(prev, val);
      return obj;
    }, {})
}())

export default (qs["_cyguid"] || guid)

export function getCookieGuid() {
  return guid
}

export function getQsGuid() {
  return qs["_cyguid"]
}
