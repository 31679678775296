import request from '../request'
import pay from './pay'
import media from './media'
import soundwave from './soundwave'
import album from './album'
import checkin from './checkin'
import custom from './custom'
import exchange from './exchange'
import file from './file'
import activity from './activity'
import v2_creator from './v2_creator'
import v2_api from './v2_api'

let { get, post, put, getHeaders } = request('https://api.chongya.com/v1')

let req = {
    base_uri: 'https://api.chongya.com/v1',
    
    getHeaders() {
        return getHeaders()
    },

    // 登录接口
    login(data) {
        return post('/login', data)
    },
    getIpInfo() {
        return get('/getipinfo')
    },
    bindMobile(data) {
        return post('/account/binding_mobile', data)
    },
    unbindWechat(data) {
        return post('/account/untied_wechat', data)
    },
    unbindFacebook(data) {
        return post('/account/facebook/unbind', data)
    },
    // 获取个人信息
    userInfo() {
        return get('/user/profile/get')
    },
    saveUserProfile(nickname, avator, intro) {
        return post('/user/profile/save', { nickname, avator, intro })
    },
    applyCreator(subject, intro, bgImg, bgImgOssId, uri, tags, hiddenIncome, enableGuard) {
        return post('/user/applycreator', { subject, intro, bgImg, bgImgOssId, uri, tags, hiddenIncome, enableGuard })
    },
    editCreator(subject, intro, bgImg, bgImgOssId, uri, tags, hiddenIncome, enableGuard, disableSupport) {
        return post('/creator/profile/save', { subject, intro, bgImg, bgImgOssId, uri, tags, hiddenIncome, enableGuard, disableSupport })
    },
    addVipLevel(id, levelName, levelIntro, levelCost) {
        return post('/creator/viplevel/save', { id, levelName, levelIntro, levelCost })
    },
    addArticle(data) {
        return post('/creator/article/add', data)
    },
    getArticle(articleId) {
        return get('/getarticle', { articleId })
    },
    visitArticle(articleId) {
        return get('/visitarticle', { articleId })
    },
    visitCreator(cid) {
        return get('/visitcreator', { cid })
    },
    getCreatorViplevelList() {
        return get('/creator/viplevel/list')
    },
    getCreatorProfile() {
        return get('/creator/profile/get')
    },
    getStat() {
        return get('/creator/getstats')
    },
    getArticleList(pageNo) {
        return get('/creator/article/list', { pageNo })
    },
    setArticleTop(id, isTop) {
        return post('/article/settop', { id, isTop })
    },
    getcreatorviplevels(cid) {
        return get('/getcreatorviplevels', { cid })
    },
    sendArtComment({ articleId, content, replyToCommentId }) {
        return post('/article/comment/reply', { articleId, content, replyToCommentId })
    },
    delArtComment(id) {
        return post('/article/comment/del', { id })
    },
    getArtCommnet(articleId, pageNo, order = 'recent') {
        return get('/article/comment/list', { articleId, pageNo, order })
    },
    getPublicCreatorProfile(id) {
        return get('/getcreatorprofile', { cid: id })
    },
    getArticleFilters(cid) {
        return get('/getarticlefilters', { cid })
    },
    getArticles(cid, pageNo, filter) {
        return get('/getarticles', { cid, pageNo, filter })
    },
    getarticlecomments(articleIds, order = 'recent') {
        return get('/getarticlecomments', { articleIds, order })
    },
    followCreator(cid) {
        return post('/user/followcreator', { cid })
    },
    unFollowCreator(cid) {
        return post('/user/unfollowcreator', { cid })
    },
    userFeed(filter = 0, pageNo = 1) {
        return get('/user/feed/list', { filter, pageNo })
    },
    logOut() {
        return post('/logout')
    },
    loginUserSupportList(pageNo = 1) {
        return get('/user/support/list', { pageNo })
    },
    getuserprofileByCid(uid) {
        return get('/getuserprofile', { uid })
    },
    artLike(articleId) {
        return post('/article/like', { articleId })
    },
    artUnLike(articleId) {
        return post('/article/unlike', { articleId })
    },
    getLikedList(articleId, pageNo = 1) {
        return get('/getarticlelikes', { articleId, pageNo })
    },
    getRecommendFeeds(tab, pageNo) {
        return get('/getpubliccreators', { tab, pageNo })
    },
    getNotifyCount() {
        return get('/notification/count')
    },
    getArticleToEdit(id) {
        return get('/creator/article/get', { id })
    },
    editSaveArticle(data) {
        return post('/creator/article/save', data)
    },
    setTopVipLevel(id) {
        return post('/creator/viplevel/settop', { id })
    },
    unsetTopVipLevel(id) {
        return post('/creator/viplevel/unsettop', { id })
    },
    getCreatorThreeSupport(cid) {
        return get('/getcreatorpublicmsgs', { cid })
    },
    saveDraft(data) {
        return post('/creator/draft/save', data)
    },

    replySupportComment(replyToMessageId, content, isPublic) {
        return post('/creator/supportmessage/reply', { replyToMessageId, content, isPublic })
    },
    setSupportPublicStatus(messageId, isPublic) {
        return post('/creator/supportmessage/setpublicstatus', { messageId, isPublic })
    },
    getSupportMessageFilters() {
        return get('/creator/supportmessage/filters')
    },

    deleteArt(id) {
        return post('/creator/article/del', { id })
    },
    sendsmscaptcha(mobile, opts = {}) {
        return post('/sendsmscaptcha', { mobile, ...opts })
    },
    accountSmsSend(mobile, opts = {}) {
        return post('/account/sendsmscaptcha', { mobile, ...opts })
    },
    deleteLevel(id) {
        return post('/creator/viplevel/del', { id })
    },
    getBestUpdates(pageNo) {
        return get('/get_best_updates', { pageNo })
    },
    getRecommendCategoryList(tab, pageNo) {
        return get('/get_recommend_category_list', { tab, pageNo })
    },
    getPlayUrl(articleId) {
        return get('/play', { articleId })
    },
    getStarCreators() {
        return get('/get_stars')
    },
    getRemoteImage(url) {
        return get('/upload/article/external-image', { url })
    },
    getContacts(data) {
        return get('/messages', data)
    },
    getMessages(conversation, data) {
        return get(`/conversation/${conversation}/messages`, data)
    },
    fetchConversationUnread(conversation) {
        return get(`/conversation/${conversation}/unread`)
    },
    getUnreadMessageCount() {
        return get('/message/count')
    },
    sendMessage(data) {
        return post('/messages', data)
    },
    markMessageRead(conversation) {
        return put(`/message/conversation/${conversation}/read`)
    },
    getMessageUnreadCount() {
        return get('/message/count')
    },
    getMessageToken() {
        return get('/message/token')
    },
    getSingleMessage(conversation, data) {
        return get(`/conversation/${conversation}/message`, data)
    },
    connectConversation(data) {
        return post('/connect/conversation', data)
    },
    getMessageCount() {
        return get('/notification/message/count')
    },
    getLastSubscription(vip_level_id) {
        return get('/user/last_subscribe', { vip_level_id })
    },
    getAppDownloadLink(data) {
        return get('/app_download_link', data)
    },


    search(q, pageNo) {
        return get('/search', { q, pageNo })
    },
    getRecommendCreators() {
        return get('/getrecommendcreators')
    },
    getUserSupports(uid, pageNo = 1) {
        return get('/getusersupports', { uid, pageNo })
    },
    getUserFollows(uid, pageNo) {
        return get('/getuserfollows', { uid, pageNo })
    },
    getCreatorFollows(cid, pageNo) {
        return get('/getcreatorfollows', { cid, pageNo })
    },
    getCreatorNotifyCount() {
        return get('/notification/creatornotifycount')
    },
    getNotifyList(pageNo) {
        return get('/notification/list', { pageNo })
    },
    setCreatorNotifyRead() {
        return post('/notification/setcreatornotifyread')
    },
    setNotifyRead(ids) {
        return post('/notification/setread', { ids })
    },
    getSupportMessageList(pageNo, filter) {
        return get('/creator/supportmessage/list', { pageNo, filter })
    },
    getCreatorsupportmsgs(cid, pageNo) {
        return get('/getcreatorsupportmsgs', { cid, pageNo })
    },
    getUserSubscription() {
        return get('/user/subscription/list')
    },
    getArticleDraft(type, articleId) {
        return get('/creator/draft/get', { type, articleId })
    },
    getOrderList(pageNo) {
        return get('/order/list', { pageNo })
    },
    getCreatorBalance() {
        return get('/creator/getbalance')
    },
    getCreatorPrivateInfo() {
        return get('/creator/privateinfo/get')
    },
    saveCreatorPrivateInfo(realName, receiptType, alipayAccount, wechatpayAccount) {
        return post('/creator/privateinfo/save', { realName, receiptType, alipayAccount, wechatpayAccount })
    },
    getCreatorCashoutInfo() {
        return get('/creator/cashout/get')
    },
    applyCashout() {
        return post('/creator/cashout/apply')
    },
    getCashoutList(pageNo) {
        return get('/creator/cashout/list', { pageNo })
    },
    getSettlementList(pageNo) {
        return get('/creator/settlement/list', { pageNo })
    },
    getSubscriptionArticle(subId) {
        return get(`/level/${subId}/subscription`)
    },
    viewArticleImages(articleId) {
        return get('/article/imageviews', { articleId })
    }

}

export default Object.assign(req, pay, media, soundwave, album, checkin, custom, exchange, file, activity, v2_creator, v2_api);
