import request from '../request'


let { get, post, deletes, put, patch } = request('https://api.chongya.com/v1')

let req = {

  // 获取作品集列表
  getAlbumList(cid = '', number) {
    return get('/albums', { cid, number })
  },

  // 创建作品集
  createAlbum(data) {
    return post('/albums', data)
  },

  // 编辑作品集
  compileAlbum(data, id) {
    return patch('/albums/' + id, data)
  },

  // 删除作品集
  deleteAlbum(id) {
    return deletes('/albums/' + id)
  },

  // 作品集排序
  sortAlbumList(id, move) {
    return put('/albums/' + id + '/sort', { move })
  },

  // 查看作品集信息
  getAlbumInfo(id) {
    return get('/albums/' + id)
  },

  getAlbumUserPermission(albumId) {
    return get(`/albums/${albumId}/user/permission`)
  },

  // 作品集添加文章
  albumAddArticle(id, data) {
    return post('/albums/' + id + '/articles', data)
  },

  // 获取作品集文章列表
  getAlbumArticlesList(id, order) {
    return get('/albums/' + id + '/articles', { order })
  },

  // 删除作品集中的文章
  delAlbumArticle(albumId, articleId) {
    return deletes('/albums/' + albumId + '/articles/' + articleId)
  },

  // 作品集文章列表排序
  sortAlbumArticleList(albumId, articleId, move) {
    return put('/albums/' + albumId + '/articles/' + articleId + '/sort', { move })
  },

  // 获取文章关联的作品集
  getArticleBelongAlbum(article_ids) {
    return get('/articles/albums', { article_ids })
  },

  // 获取作品page信息
  getArticlePageInfo(articleId) {
    return get('/articles/' + articleId + '/page')
  },

  // 添加文章到作品集
  articleAddAlbum(id, data) {
    return post('/articles/' + id + '/albums', data)
  },

  getAlbumComments(albumId, page, order = 'recent') {
    return get(`/albums/${albumId}/comments`, { page, order })
  },

  sendAlbumComment(albumId, content, reply_to_comment_id) {
    return post(`/albums/${albumId}/comments`, { content, reply_to_comment_id })
  },

  countAlbumComments(albumId) {
    return get(`/albums/${albumId}/comments/count`)
  },

  deleteAlbumComment(albumId, commentId) {
    return deletes(`/albums/${albumId}/comments/${commentId}`)
  },

  visitAlbumPV(albumId) {
    return get(`/albums/${albumId}/pv`)
  },

  getAlbumFeed(albumId, order) {
    return get(`/albums/${albumId}/feeds`, { order })
  },

};

export default req
