import request from '../request'

let { get, post, deletes, put, patch } = request('https://api.chongya.com/v1')

let req = {

    getTopicList(uri, order, page, page_size) {
        return get(`/article/topic/uri/${uri}`, { order, page, page_size })
    },

    getSpecialList(page) {
        return get('/specials', { page })
    },

    getSpecialDetails(special, page, page_size) {
        return get(`/specials/${special}/commens`, { page, page_size })
    },

    getRmsList(position) {
        return get('/rms/list', { position })
    },

    visitTopicPV(uri) {
        return patch(`/article/topic/uri/${uri}/pv`)
    },

    visitSpecialPV(uri) {
        return patch(`/specials/${uri}/pv`)
    },

};

export default req
