<template>
    <div class="tc">
        <modal name="confirm" :clickToClose="false" :width="600" height="auto" @before-open="beforeOpen">
            <div style="padding: 24px">
                <div class="title" v-text="confirmData.title"></div>
                <div class="close" @click="$modal.hide('confirm')"><span class="iconfont iconshanchu-line1"></span>
                </div>
                <div class="msg-of-tc-box" v-text="confirmData.content">
                </div>
                <div class="btn-box">
                    <span class="close-btn" @click="$modal.hide('confirm')" v-text="confirmData.closeText"></span>
                    <span class="confirm-btn" v-text="confirmData.confirmText" @click="isConfirm"></span>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        confirmData: {
          title:'',
          content:'',
          closeText:'',
          confirmText:''
        },
        confirmFun:null
      }
    },
    name: "confirmBox",
    methods: {
      beforeOpen(event) {
        this.confirmData = event.params.data
        this.confirmFun = event.params.confirm
      },
      isConfirm(){
        this.confirmFun()
        this.$modal.hide('confirm')
      }
    }
  }
</script>

<style lang="less">
    @import "../lib/general";
    @import "../lib/tc";

    .msg-of-tc-box {
        font-size: 16px;
        text-align: center;
        margin-top: 30px;
    }

    .close-btn {
        .btn-color-gray1();
        width: 112px;
        height: 40px;
        border-radius: 20px;
        border: solid 1px #bbbbbb;
        display: inline-block;
        color: #666;
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        vertical-align: middle;
        margin-right: 20px;
        cursor: pointer;
    }

    .confirm-btn {
        .btn-color-normal();
        width: 112px;
        height: 40px;
        border-radius: 20px;
        display: inline-block;
        background-color: #fcdc2c;
        color: #000;
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        vertical-align: middle;
        cursor: pointer;
    }

    .btn-box {
        margin-top: 40px;
        text-align: center;
        margin-bottom: 16px;
    }
</style>
