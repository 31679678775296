import 'babel-polyfill'
import 'url-polyfill'
import 'events-polyfill'
import './polyfill'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import infiniteScroll from 'vue-infinite-scroll'
import CheckboxRadio from 'vue-checkbox-radio'
import VueUploadComponent from 'vue-upload-component'
import vueSmoothScroll from 'vue-smoothscroll'
import detector from "detector"

import Toast from 'vue2-toast'
import 'vue2-toast/lib/toast.css'

import './icon/iconfont.css'
import './init.css'
import common from './common'
import tool from './tool'
import cyuri from './uri'
import req from '../api/vRequest'
import photoPreview from '@chongya/vue-photo-preview/src/lib/index'
import '@chongya/vue-photo-preview/dist/skin.css'

Vue.use(photoPreview, { closeOnScroll: false })
Vue.use(VueMeta)
Vue.use(vueSmoothScroll)
Vue.use(Toast)
Vue.use(infiniteScroll)
Vue.use(VModal)
Vue.use(CheckboxRadio)
Vue.use(req)
Vue.use(cyuri)
Vue.use(tool)
Vue.component('file-upload', VueUploadComponent)

export default {
    Vue
}

if (detector.browser.ie) {
    const htmlEl = document.querySelector('html')
    let htmlClass = htmlEl.className ? htmlEl.className.split(' ') : []
    htmlClass.push('ie')
    let version = String(detector.browser.version || '')
    if (version) {
        htmlClass.push(`ie${version.split('.')[0]}`)
    }
    htmlEl.className = htmlClass.join(' ')
}

// 初始化推广码
if (window.localStorage && window.localStorage.setItem) {
    const promoCode = common.parseQS()['__promo_code']
    if (promoCode) {
        window.localStorage.setItem('promo-code', promoCode)
    }
}

if (process.env.NODE_ENV === "production") {
    // 百度统计
    (function() {
        window._hmt = window._hmt || [];
        const hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?76d85e3fcd518b7106295ce37cfde80c";
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(hm);
    })();

    // 百度推送
    (function() {
        const bp = document.createElement('script');
        const curProtocol = window.location.protocol.split(':')[0];
        if (curProtocol === 'https') {
            bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
        } else {
            bp.src = 'http://push.zhanzhang.baidu.com/push.js';
        }
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(bp);
    })();

    /*
    (function() {
        const s = document.createElement("script");
        s.src = "https://static.chongyacdn.com/static/sentry/5.5.0/bundle.min.js";
        s.crossorigin = "anonymous"
        s.onload = function() {
            Sentry.init({ dsn: 'https://f8893cf2ecc54983be40fc3a7bad2ee9@sentry.chongya.com/5' });
        }
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(s);
    })();
    */
}

//阿里云盾人际验证
(function() {
    var s = document.createElement("script");
    s.src = 'https://g.alicdn.com/sd/ncpc/nc.js?t=2015052012';
    s.charset = 'utf-8';
    // s.onload = function() {}
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(s);
})();
