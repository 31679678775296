import request from '../request'


let { get, post } = request('')

let req = {
  getHdConfig() {
    return get('https://static.chongyacdn.com/config/hd.json', {}, { headers: {}, withCredentials: false })
  },
};

export default req
