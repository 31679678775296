import moment from 'moment'
import common from './common'
import detectInfo from './detector'
import api from '../api/api/index'
import { setBaseFileUri } from '../api/api/file'

moment.locale('zh-cn');

const BETA_SERVICE_CREADER_ID = 'e8bb5048ad5d46b595ac3adcc4d098cd'
const BETA_SERVICE_CREADER_UID = '69e1229e60044501be70f99254ff6ddc'
const BETA_SERVICE_C_CREADER_ID = '8d6eb9146287498ea2d02f73b9f5da16'
const BETA_SERVICE_C_CREADER_UID = '085875c9aa234403aca475ba2913f0b1'

const ONLINE_SERVICE_CREADER_ID = 'b9766e7e40fd4f1a8ec4d5b28e8859d4' // 线上`冲呀服务号` cid
const ONLINE_SERVICE_CREADER_UID = '0a3a4bcce41b41938ada39bfd9f9b23f' // 线上`冲呀服务号` uid
const ONLINE_SERVICE_C_CREADER_ID = '2687882e4ae446cfbf9854a65f451290' // 线上`冲呀创作者服务号` cid
const ONLINE_SERVICE_C_CREADER_UID = '8864db336746403f8529c6b2ffdc03da' // 线上`冲呀创作者服务号` uid

const nowtime = new Date().getTime()
// 服务器时间
let serverTime = nowtime
let serverTimeAdjuster = nowtime

const tools = Object.assign({}, common, {
    BLANK_IMG: "https://static.chongyacdn.com/common/blank.gif",

    BETA_SERVICE_CREADER_ID: BETA_SERVICE_CREADER_ID,
    BETA_SERVICE_CREADER_UID: BETA_SERVICE_CREADER_UID,
    BETA_SERVICE_C_CREADER_ID: BETA_SERVICE_C_CREADER_ID,
    BETA_SERVICE_C_CREADER_UID: BETA_SERVICE_C_CREADER_UID,

    ONLINE_SERVICE_CREADER_ID: ONLINE_SERVICE_CREADER_ID,
    ONLINE_SERVICE_CREADER_UID: ONLINE_SERVICE_CREADER_UID,
    ONLINE_SERVICE_C_CREADER_ID: ONLINE_SERVICE_C_CREADER_ID,
    ONLINE_SERVICE_C_CREADER_UID: ONLINE_SERVICE_C_CREADER_UID,

    // 验证是否为官方账号 creator id
    isChongyaOfficialCid(cid) {
        return cid == ONLINE_SERVICE_CREADER_ID || cid == ONLINE_SERVICE_C_CREADER_ID // `线上`官方账号
            || cid == BETA_SERVICE_CREADER_ID || cid == BETA_SERVICE_C_CREADER_ID // `测试`账号
    },

    isChongyaOfficialUid(uid) {
        return uid == ONLINE_SERVICE_CREADER_UID || uid == ONLINE_SERVICE_C_CREADER_UID // `线上`官方账号
            || uid == BETA_SERVICE_CREADER_UID || uid == BETA_SERVICE_C_CREADER_UID // `测试`账号
    },

    isChongyaDomain(url) {
        if (url) {
            url = String(url).toLowerCase()
            return /^https?:\/\/(\w+\.)*chongya\.com(\/|$)/.test(url)
        }
        return false
    },

    setServerTime(time) {
        serverTimeAdjuster = new Date().getTime()
        serverTime = time * 1e3 // 由于服务器回传时间是`Unix Timestamp`（单位：秒），所以需要转换为毫秒
    },

    // 获取服务器时间
    getServerTime() {
        return serverTime + (new Date().getTime() - serverTimeAdjuster)
    },

    formatServerTime(format = 'YYYY-MM-DD HH:mm:ss') {
        return moment(this.getServerTime()).format(format)
    },

    formatTime(t, format) {
        return moment(t, 'YYYY-MM-DD HH:mm:ss').format(format)
    },

    formatDate(dateStr, originFormat, exportFormat) {
        return moment(dateStr, originFormat).format(exportFormat)
    },

    convertArticleTime(t) {
        return moment(t, 'YYYY-MM-DD HH:mm:ss').format('YYYY年MM月DD日 HH:mm')
    },
    nowDate() {
        return moment().format('YYYY年MM月DD日 HH:mm')
    },

    convertCommentTime(t, format = 'YYYY年MM月') {
        t = moment(t, 'YYYY-MM-DD HH:mm:ss')
        let v = t.valueOf()
        let now = Date.now()
        if (t + 10 * 6e4 >= now) { // 10分钟以内
            return '刚刚'
        } else if (t + 10 * 6e4 <= now && t + 60 * 6e4 > now) { // 10分钟～1小时
            return t.fromNow();
        } else if (t + 60 * 6e4 <= now && t + 24 * 60 * 6e4 > now) { // 1小时～24小时
            return t.fromNow();
        } else if (t + 24 * 60 * 6e4 <= now && t + 30 * 24 * 60 * 6e4 > now) { // 24小时～30天
            return t.fromNow();
        }
        return t.format(format)
    },

    convertNewCommentTime(t) {
        t = moment(t, 'YYYY-MM-DD HH:mm:ss')
        let v = t.valueOf()
        let now = Date.now()
        if (t + 10 * 6e4 >= now) { // 10分钟以内
            return '刚刚'
        } else if (t + 10 * 6e4 <= now && t + 60 * 6e4 > now) { // 10分钟～1小时
            return t.fromNow();
        } else if (t + 60 * 6e4 <= now && t + 24 * 60 * 6e4 > now) { // 1小时～24小时
            return t.fromNow();
        } else if (t + 24 * 60 * 6e4 <= now && t + 30 * 24 * 60 * 6e4 > now) { // 24小时～30天
            return t.fromNow();
        }
        return t.format('YYYY-MM-DD HH:mm')
    },

    formatMoney(num = 0) {
        num = num / 100
        let source = String(num).split('.');
        source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'), '$1,');
        return source.join('.');
    },

    resizeCdnImage(url, w = 100, h = 100) {
        if (!url) {
            return url
        }

        /* 忽略hash，阿里云图片没有hash参数 */
        const processParam = 'x-oss-process='
        let qs = {}
        let qIndex = url.indexOf('?')
        let qsStr = ''
        if (qIndex > -1) {
            qsStr = url.substr(qIndex + 1)
            if (qsStr.substr(0, processParam.length) === processParam) {
                qsStr = `${processParam}image/resize,m_fill,w_${w},h_${h},${qsStr.substr(processParam.length)}`
            } else {
                qsStr = `${qsStr}&${processParam}image/resize,m_fill,w_${w},h_${h}`
            }
        }
        if (!qsStr) {
            qsStr = `${processParam}image/resize,m_fill,w_${w},h_${h}`
        }

        let baseUrl = url
        if (qIndex > -1) {
            baseUrl = url.substring(0, qIndex)
        }
        return `${baseUrl}?${qsStr}`
    },

    resizeCdnImageCustom(url, m = 'fill', w, h) {
        if (!url) {
            return url
        }

        let hasW = typeof w === 'number' && !isNaN(w)
        let hasH = typeof h === 'number' && !isNaN(h)
        if (!hasW && !hasH) {
            return url
        }

        let resizeParam = ''
        if (hasW && hasH) {
            resizeParam = `m_${m},w_${w},h_${h}`
        } else if (hasW && !hasH) {
            resizeParam = `m_${m},w_${w}`
        } else if (!hasW && hasH) {
            resizeParam = `m_${m},h_${h}`
        }

        /* 忽略hash，阿里云图片没有hash参数 */
        const processParam = 'x-oss-process='
        let qs = {}
        let qIndex = url.indexOf('?')
        let qsStr = ''
        if (qIndex > -1) {
            qsStr = url.substr(qIndex + 1)
            if (qsStr.substr(0, processParam.length) === processParam) {
                qsStr = `${processParam}image/resize,${resizeParam},${qsStr.substr(processParam.length)}`
            } else {
                qsStr = `${qsStr}&${processParam}image/resize,${resizeParam}`
            }
        }
        if (!qsStr) {
            qsStr = `${processParam}image/resize,${resizeParam}`
        }

        let baseUrl = url
        if (qIndex > -1) {
            baseUrl = url.substring(0, qIndex)
        }
        return `${baseUrl}?${qsStr}`
    },

    escapeHtml(str) {
        if (!str) {
            return ''
        }
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
    },

    decodeHtmlUrl(str, selfWindow = false) {
        str = this.escapeHtml(str)
        // (https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]
        return str.replace(/https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g, (v) => `<a href="${v}" target="${selfWindow ? '_self' : '_blank'}">${v}</a>`)
    },

    // 对字符串加*显示
    maskString(str) {
        if (!str) {
            return str
        }
        str = String(str)

        let l = str.length
        if (l === 1) {
            // 字符串太短，无法处理
            return str
        }
        if (l <= 3) {
            return "*" + str.substr(1)
        }
        if (l <= 5) {
            return "**" + str.substr(2)
        }

        let cl = 3
        let mask = "***"
        if (l < 9) {
            let cl = 2
            mask = "**"
        }

        if (l >= 15) {
            cl = 4
        }

        return str.substr(0, cl) + mask + str.substring(l - cl)
    },

    isLowBrowser() {
        if (detectInfo.browser === "ie") {
            try {
                if (window.localStorage && window.localStorage.getItem) {
                    const closeStr = window.localStorage.getItem("low_browser_tip")
                    let tip = {}
                    try {
                        tip = JSON.parse(closeStr)
                    } catch (err) {
                    }
                    if (tip.close === 1 && tip.expires > new Date().getTime()) {
                        return false
                    }
                }

                // let ver = detectInfo.browser_version.split(".")
                // if (ver.length > 0 && ver[0]) {
                //   return parseInt(ver[0]) < 11
                // }
            } catch (err) {
            }

            return true
        }
        return false
    },

    computedUnlockTypes({ visibleLevel, vipLevels, isSingleLock, singlePrice, albumList, createTime }, serverTime) {
        let unlockTypes = []
        if (visibleLevel !== 1) {
            if (visibleLevel === 2) {
                unlockTypes.push({
                    type: 1,
                    data: {},
                })
            }
            if (visibleLevel === 3 && vipLevels && vipLevels.length > 0) {
                let month, subscribeMonth
                if (createTime) {
                    const ct = moment(createTime, "YYYY-MM-DD HH:mm:ss")
                    month = ct.format('M月')
                    if (ct.isBefore(serverTime, 'month')) {
                        subscribeMonth = ct.format('YYYYMM')
                    }
                }
                vipLevels.forEach(v => {
                    unlockTypes.push({
                        type: 2,
                        data: v.vipLevel,
                        month,
                        subscribeMonth,
                    })
                })
            }
            if (albumList && albumList.length > 0) {
                albumList.forEach(v => {
                    if (v.isLock === 1) {
                        unlockTypes.push({
                            type: 3,
                            data: v,
                        })
                    }
                })
            }
            if (visibleLevel === 3 && isSingleLock === 1) {
                unlockTypes.push({
                    type: 4,
                    data: { price: singlePrice },
                })
            }
        }
        return unlockTypes
    },

    // 活动配置
    getHdConfig: (function() {
        let activityConfig = {}
        return function(position) {
            if (activityConfig[position]) {
                if (activityConfig[position].data) {
                    return Promise.resolve(activityConfig[position].data)
                } else if (activityConfig[position].promise) {
                    return activityConfig[position].promise
                }
            }

            const reqPromise = new Promise((resolve, reject) => {
                api.getRmsList(position).then(data => {
                    activityConfig[position] = {
                        promise: null,
                        data: data,
                    }
                    resolve(data)
                }).catch(reject)
            })
            activityConfig[position] = {
                promise: reqPromise,
                data: null
            }
            return reqPromise
        }
    }()),

    addClassName(clasName = '', newClass) {
        clasName = clasName.trim()
        let classArr = clasName.split(' ')
        classArr = classArr.filter(v => {
            v = v.trim()
            return !v && v !== newClass
        })
        classArr.push(newClass)
        return classArr.join(' ')
    },

    removeClassName(clasName = '', removeClass) {
        clasName = clasName.trim()
        if (!clasName) {
            return clasName
        }
        let classArr = clasName.split(' ')
        classArr = classArr.filter(v => {
            v = v.trim()
            return !v && v !== removeClass
        })
        return classArr.join(' ')
    },

    secondToTime(second) {
        const add0 = num => (num < 10 ? "0" + num : "" + num)
        const hour = Math.floor(second / 3600)
        const min = Math.floor((second - hour * 3600) / 60)
        const sec = Math.floor(second - hour * 3600 - min * 60)
        return (hour > 0 ? [hour, min, sec] : [min, sec]).map(add0).join(":")
    },

    genImageSize({
        width,
        height,
        maxWidth = 240,
        maxHeight = 240,
        minVerticalScale = 21 / 9,
        minHorizontalScale = 9 / 21,
    }) {
        if (!width || !height) {
            return {
                width: 120,
                height: 120
            }
        }
        // 竖图
        if (height >= width) {
            return {
                height: maxHeight,
                width: height / width > minVerticalScale
                    ? maxHeight / minVerticalScale
                    : (maxHeight * width) / height,
            }
        }
        // 横图
        return {
            width: maxWidth,
            height: height / width < minHorizontalScale
                ? maxWidth * minHorizontalScale
                : (maxWidth * height) / width,
        }
    },

    genChatImageSize(params) {
        const result = this.genImageSize(params)
        return {
            width: `${result.width}px`,
            height: `${result.height}px`,
        }
    },

    // 判断是否海外ip
    isAbroadIP: (function() {
        const isAbroadIPPromise = new Promise((resolve, reject) => {
            api.getIpInfo().then(({ data }) => {
                if (data && data.country_id && String(data.country_id).toUpperCase() !== 'CN') {
                    // `fileapi.chongya.com`启用了海外加速
                    setBaseFileUri('https://fileapi.chongya.com/v1')
                    resolve(true)
                    return
                }
                resolve(false)
            }).catch((err) => {
                reject(err)
            })
        })
        return () => isAbroadIPPromise
    }()),

    getFileNameSuffix: (fileName) => {
        let suffix = ''
        if (!fileName) {
            return suffix
        }
        const index = fileName.lastIndexOf('.')
        if (index > -1) {
            suffix = fileName.substr(index)
        }
        return suffix
    },

    fileSize2Text(size) {
        if (!size) return ''

        const kb = size / 1024
        if (kb < 1024) {
            return `${Math.ceil(kb)}KB`
        }

        const mb = kb / 1024
        if (mb < 1024) {
            return `${mb.toFixed(1)}MB`
        }

        return `${(mb / 1024).toFixed(2)}GB`
    },

    openWindow(url) {
        if (detectInfo.browser === 'safari') {
            window.location.href = url
        } else {
            window.open(url, '_blank')
        }
    }

})

export default {
    install(Vue, options) {
        Vue.prototype.$tool = tools
    }
}
