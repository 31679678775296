import axios from 'axios'
import guid from '../lib/guid'
import detectInfo from '../lib/detector'

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

function getHeaders() {
  let cCookie = ''
  try {
    const ma = document.cookie.match(/C=([^;]+)(;|$)/)
    cCookie = decodeURIComponent(ma && ma[1] ? ma[1] : '')
  } catch (err) {
  }
  return {
    'X-Csrf-Token': cCookie,
    'X-CY-Guid': guid,                                  // 用户跟踪，唯一标识
    'X-CY-Device': detectInfo.device,                   // 设备类型
    'X-CY-Device-Model': detectInfo.device_model,       // 设备型号
    'X-CY-OS': detectInfo.os,                           // 操作系统
    'X-CY-OS-Version': detectInfo.os_version,           // 系统版本
    'X-CY-Browser': detectInfo.browser,                 // 浏览器类型
    'X-CY-Browser-Version': detectInfo.browser_version, // 浏览器版本
    'X-CY-App': detectInfo.app,                         // APP类型
    'X-CY-App-Version': detectInfo.app_version,         // APP版本
  }
}

export default function(base_url) {
  function wrapAxios(axiosPromise, resolve, reject) {
    return axiosPromise.then(({ data }) => {
      resolve(data)
    }).catch(err => {
      const { response } = err
      if (response) {
        if (response.status === 422) {
          reject(response.data)
          return
        } else if (response.status === 500) {
          reject({ errno: 500, errmsg: '内部系统错误' })
          return
        } else if (response.status === 401) {
          // 401错误是指“用户未登录”，`reject()`不要回传`errmsg`参数
          // 回传`errmsg`会弹出 `toast`提示
          // 一些无需登录的也能访问的页面，如创作者主页，会调用类似`/user/profile/get`接口，此接口会验证登录状态，未登录会响应401
          // 如果回传`errmsg`，页面会弹出`未登录`提示，但在这类页面其实不需要弹提示
          reject({ errno: 401 })
          return
        }
      }
      reject(err)
    })
  }

  return {
    getHeaders() {
      return getHeaders()
    },

    get(url, params = {}, config = {}) {
      return new Promise((resolve, reject) => {
        wrapAxios(axios.get(base_url + url, {
          params: {
            _t: Date.now(), // 请求加时间戳，防止浏览器缓存，尤其是IE
            ...params,
          },
          withCredentials: true,
          headers: getHeaders(),
          ...config,
        }), resolve, reject)
      })
    },

    post(url, data = {}, config = {}) {
      return new Promise((resolve, reject) => {
        wrapAxios(axios.post(base_url + url, data, {
          withCredentials: true,
          headers: getHeaders(),
          ...config,
        }), resolve, reject)
      })
    },

    put(url, data = {}, config = {}) {
      return new Promise((resolve, reject) => {
        wrapAxios(axios.put(base_url + url, data, {
          withCredentials: true,
          headers: getHeaders(),
          ...config,
        }), resolve, reject)
      })
    },

    patch(url, data = {}, config = {}) {
      return new Promise((resolve, reject) => {
        wrapAxios(axios.patch(base_url + url, data, {
          withCredentials: true,
          headers: getHeaders(),
          ...config,
        }), resolve, reject)
      })
    },

    deletes(url, config = {}) {
      return new Promise((resolve, reject) => {
        wrapAxios(axios.delete(base_url + url, {
          withCredentials: true,
          headers: getHeaders(),
          ...config,
        }), resolve, reject)
      })
    },

  }

}


