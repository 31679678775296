import request from '../request'

let { get, post, put } = request('https://activity.chongya.com/v1')

let req = {

    getcdKey(orderId) {
        return get(`/exchanges/order/${orderId}`)
    },

    getBatchCdKey(data) {
        return get(`/exchanges/orders`, data)
    },

    getExchangeRecord(page) {
        return get('/user/exchanges', { page })
    },

    exchangesCdKey(cdkey) {
        return put(`/exchanges/cdkey/${cdkey}`)
    },
}

export default req