import request from '../request'

let { get, post, deletes, put, patch } = request('https://api.chongya.com/v2')

let req = {

    getCreatorPrivateInfoV2() {
        return get('/creator/privateinfo/get')
    },

    saveCreatorPrivateInfoV2(receiptType, receiptAccount, receiptRealName, receiptIdnumber, receiptPhone, receiptKaihuhang) {
        return post('/creator/privateinfo/save', { receiptType, receiptAccount, receiptRealName, receiptIdnumber, receiptPhone, receiptKaihuhang })
    },

    getCreatorCashoutInfoV2() {
        return get('/creator/cashout/get')
    },

    applyCashoutV2() {
        return post('/creator/cashout/apply')
    },

};

export default req
