import request from '../request'


let {get, deletes} = request('https://media.chongya.com/v1')

let req = {
  getRunStatus(id) {
    return get(`/medias/${id}/runstatus`)
  },
  medias(page_no, media_type) {
    return get('/medias', {page_no, media_type})
  },
  deleteMedia(id){
    return deletes(`/medias/${id}`)
  },
  getMedia(oss_id){
    return get(`/medias/${oss_id}`)
  }

};

export default req
