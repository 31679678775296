import request from '../request'


let {get, post} = request('https://pay.chongya.com/v1')

let req = {
  support(data) {
    return post('/orders/support', data)
  },
  subscribe(data) {
    return post('/orders/subscribe', data)
  },
  subscribeHistory(data) {
    return post('/orders/subscribe_history', data)
  },
  singleUnlock(data) {
    return post('/orders/single_sale', data)
  },
  albumUnlock(data) {
    return post('/orders/album_sell', data)
  },
  orderMsg(id) {
    return get('/order/' + id)
  },
};

export default req
