import detector from "detector"

const DEFAULT_APP = "mainsite"

const wechatInfo = (function() {
  let info = {
    "wechat": false,
  }
  const ma = navigator.userAgent.toLocaleLowerCase().match(/micromessenger\/([\d\.]+)\b/)
  if (ma && ma[0]) {
    info.wechat = true
    info.version = ma[1] || ""
  }
  return info
}())

const qqInfo = (function() {
  let info = {
    "qq": false,
  }
  const ma = navigator.userAgent.toLocaleLowerCase().match(/qq\/([\d\.]+)\b/)
  if (ma && ma[0]) {
    info.qq = true
    info.version = ma[1] || ""
  }
  return info
}())

const weiboInfo = (function() {
  let info = {
    "weibo": false,
  }
  const ma = navigator.userAgent.toLocaleLowerCase().match(/__weibo__([\d\.]+)__/)
  if (ma && ma[0]) {
    info.weibo = true
    info.version = ma[1] || ""
  }
  return info
}())

const detectInfo = (function() {
  const d = detector.parse(navigator.userAgent)
  let detectInfo = {
    "device": d.device.name,
    "device_model": "",
    "os": d.os.name,
    "os_version": d.os.fullVersion,
    "browser": d.browser.name,
    "browser_version": d.browser.fullVersion,
    "app": DEFAULT_APP,
    "app_version": "",
  }
  if (wechatInfo.wechat) {
    detectInfo.browser = "wechat"
    detectInfo.browser_version = wechatInfo.version
  } else if (qqInfo.qq) {
    detectInfo.browser = "qqchat"
    detectInfo.browser_version = qqInfo.version
  } else if (weiboInfo.weibo) {
    detectInfo.browser = "weibo"
    detectInfo.browser_version = weiboInfo.version
  }
  return detectInfo
}())

let mixedDetectInfo = Object.assign({}, detectInfo)

const qs = (function() {
  var decode = decodeURIComponent;
  let str = location.search || ''
  if (str.length > 1) { // 去掉`?`问号
    str = str.substr(1)
  }
  return str
    .replace(/\+/g, ' ')
    .split('&')
    .filter(Boolean)
    .reduce(function(obj, item, index) {
      var ref = item.split('=');
      var key = decode(ref[0] || '');
      var val = decode(ref[1] || '');
      var prev = obj[key];
      obj[key] = prev === undefined ? val : [].concat(prev, val);
      return obj;
    }, {})
}())

for (let o in qs) {
  if (o == '_cydevice' && qs[o]) {
    mixedDetectInfo['device'] = qs[o]
  } else if (o == '_cydevicemodel' && qs[o]) {
    mixedDetectInfo['device_model'] = qs[o]
  } else if (o == '_cyos' && qs[o]) {
    mixedDetectInfo['os'] = qs[o]
  } else if (o == '_cyosversion' && qs[o]) {
    mixedDetectInfo['os_version'] = qs[o]
  } else if (o == '_cybrowser' && qs[o]) {
    mixedDetectInfo['browser'] = qs[o]
  } else if (o == '_cybrowserversion' && qs[o]) {
    mixedDetectInfo['browser_version'] = qs[o]
  } else if (o == '_cyapp' && qs[o]) {
    mixedDetectInfo['app'] = qs[o]
  } else if (o == '_cyappversion' && qs[o]) {
    mixedDetectInfo['app_version'] = qs[o]
  }
}

export default mixedDetectInfo

export function getPlainDetectInfo() {
  return detectInfo
}
