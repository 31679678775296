import detectInfo from "./detector"
import api from "../api/api"

class Soundwave {
  constructor(options = {}) {
    this._onmessage = options.onmessage
    this.connected = false
    this.retryCount = 0
  }

  connect() {
    if (this.connecting || this.ws) {
      return
    }
    this.connecting = true
    api.getWebsocketToken().then(({ data }) => {
      this.initWebSocket(data.token)
    }).catch(err => {
      // console.error('init websocket error.', err)
    }).finally(() => {
      this.connecting = false
    })
  }

  initWebSocket(token) {
    let ws = new window.WebSocket(`wss://sw.chongya.com:20443/sub?token=${token}&detector=${encodeURIComponent(JSON.stringify(detectInfo))}`)
    this.ws = ws
    ws.addEventListener('open', (event) => {
      // console.log('websocket connected ', event);
      this.connected = true
      this.retryCount = 0
      this.initHeartbeat()
    });
    ws.addEventListener('close', (event) => {
      // console.log('websocket closed ', event);
      if (event.code >= 1002 && event.code <= 1013 && this.retryCount < 5) {
        this.retryCount++
        clearTimeout(this.retryTimer)
        this.retryTimer = setTimeout(() => {
          this.reconnect()
        }, 1e4)
      } else {
        this.close()
      }
    });
    ws.addEventListener('error', (event) => {
      // console.error('websocket occur an error:', event);
    });
    ws.addEventListener('message', (event) => {
      // console.log('websocket message:', event)
      let resp = JSON.parse(event.data)
      if (resp.type === "PONG") {
        // ignore
      } else if (resp.type === "PUSH") {
        this.messageHandler(resp.data)
      }
    });
  }

  initHeartbeat() {
    this.pong()
  }

  ping() {
    if (!this.ws) {
      return
    }
    this.ws.send(JSON.stringify({ "type": "PING", "data": {} }))
    this.pong()
  }

  pong() {
    this.pingTimer && clearTimeout(this.pingTimer)
    this.pingTimer = setTimeout(() => this.ping(), 4e4)
  }

  messageHandler(msg) {
    // console.log("message handler ", msg)
    try {
      if (this._onmessage && msg && msg.items && msg.items.length > 0) {
        msg.items.forEach(v => {
          this._onmessage(v)
        })
      }
    } catch (err) {
      console.error("websocket parse message error: ", err)
    }
  }

  reconnect() {
    if (!this.connecting) {
      this.close()
      this.connect()
    }
  }

  close() {
    clearTimeout(this.pingTimer)
    this.pingTimer = null
    clearTimeout(this.retryTimer)
    this.retryTimer = null
    if (this.ws) {
      this.ws.close()
      this.ws = null
    }
    this.connecting = false
    this.connected = false
  }

}

export default Soundwave
