/*
*
* 全部跳转链接
*
*/

import api from "../api/api";

const base_test = 'http://127.0.0.1:8080'
const base_prod = ''
const test = {
  publish: '/publish.html'
}

const prod = {
  publish: '/update/add',
  detail: '/update/',
  creator:'/creator',
  user:'/user/'
}

function addBase(data, base){
  let b = {}
  for(let key in data){
    b[key] = base+data[key]
  }
  return b
}

export default {
  install(Vue, options) {
    Vue.prototype.$cyuri = addBase(prod,base_prod)
    //Vue.prototype.$cyuri = addBase(test,base_test)
  }
}
