import request from '../request'


let {get, post} = request('https://api.chongya.com/v1')

let req = {

  getWebsocketToken() {
    return post('/websocket/token')
  },

};

export default req
