<template>
    <div>
        <modal name="login1" :clickToClose="false" :width="600" height="auto" @before-open="beforeOpen1"
               class="login-modal">
            <div class="tc tc-login1" v-on:keyup.enter="goLogin">
                <div v-if="!hasNo" class="close" @click="$modal.hide('login1')">
                    <span class="iconfont iconshanchu-line1"></span>
                </div>
                <div class="dlgjc-span" v-text="login1Title"></div>
                <div style="text-align: center;margin-top: 40px">
                    <input
                        class="phone-input"
                        v-model="phone"
                        placeholder="手机号"
                        :class="errorClasses.phone"
                    />
                </div>
                <div class="yzm-div">
                    <input
                        type="number"
                        class="yzm"
                        v-model="login1code"
                        placeholder="验证码"
                        maxlength="6"
                        :class="errorClasses.code"
                    />
                    <span :class="this.codeSendBtnText === '发送验证码'?'yzm-btn':'yzm-btn-cant' " @click="codeSend(0, 1)"
                          v-text="codeSendBtnText"></span>
                </div>
                <div class="error-box" v-text="login1errorText">
                </div>
                <div :class="(checkCode.status&&checkPhone.status)?'can-click login-btn':'login-btn'" @click="goLogin">
                    登录
                </div>
                <div class="yhxy textof" style="margin-top: 12px;">未注册手机验证后自动登录</div>
                <div class="text-thrid-te">第三方账号登录</div>
                <div class="text-thrid-te-div">
                    <span @click="goWechat" class="iconfont iconweixin-block"></span>
                    <span v-if="isAbroadIP" @click="goFacebook" class="iconfont iconfacebook-block"></span>
                </div>
                <div class="yhxy textof">
                    注册即代表你同意<a href="/terms" target="_blank">《用户协议》</a>和<a href="/privacy" target="_blank">《隐私政策》</a>
                </div>
                <div id="afs_nc_container1" class="afs_nc_container" v-show="afsNCShow"></div>
            </div>
        </modal>
        <modal name="login2" :clickToClose="false" :width="600" height="auto" :scrollable="true"
               @before-open="beforeOpen">
            <div class="tc tc-login2">
                <div class="title-of-tc" v-text="login2Title"></div>
                <div class="close" @click="hideLogin2"><span class="iconfont iconshanchu-line1"></span>
                </div>
                <div class="text-title" v-text="prese">
                </div>
                <div class="text-content">
                    请更新一些资料，让创作者知道是被温暖的你支持
                </div>
                <div class="box-all-cont">
                    <div>
                        <span class="span-of-left" style="padding-top: 5px">昵称</span>
                        <input class="nickname-input" v-model="nickname" :class="errorClasses.nickName" />
                        <span class="exchange-span" @click="changeNickname">
              <span class="iconfont iconshuaxin-line"></span> 换一个
            </span>
                    </div>
                    <div style="overflow: hidden;margin-top: 24px;">
                        <span class="span-of-left not-line">头像</span>
                        <div class="user-avatar-over">
                            <div style="overflow:hidden;">
                                <div class="default-avatar">
                                    <img :src="$tool.resizeCdnImage(avatarUrl,320,320)" width="100%" />
                                </div>
                                <span class="note">大小不超过10MB</span>
                                <cropper
                                    cropperName="uploadUserAvatar"
                                    @cropperData="cropperIn"
                                    :up="$api.uploadAccountAvatar"
                                    :bl="1"
                                    :size="1024*1024*10">
                                    <div class="upload-box-btn">
                                        上传头像
                                    </div>
                                </cropper>
                            </div>
                            <div style="margin-left: 20px;color: #666;margin-top: 8px;">
                                <span @click="showAllAvatar" style="cursor: pointer;">换一个 <span
                                    class="iconxiaoxiangxiajiantou-line iconfont"></span></span>
                            </div>
                            <div class="list-of-default-avatar" v-show="allAvatar">
                                <div :class="link===avatarUrl?'item-of-ava click-ava':'item-of-ava'"
                                     v-for="link in avatarList" @click="changeAvatar(link)">
                                    <img :src="link" width="100%" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="qyms-div">
                        <span class="span-of-left" style="padding-top: 10px;">个人简介</span>
                        <textarea v-model="desc" :class="errorClasses.desc">
                        </textarea>
                    </div>
                    <div style="margin-top: 40px;">
                        <span class="span-of-left">手机号</span>
                        <span class="phone-of-this" v-if="userInfo.mobile" v-text="userInfo.mobile"
                              style="margin-left: 8px"></span><span @click="bindMobile"
                                                                    class="gb-btn-a"
                                                                    v-if="userInfo.isBindMobile!==1">绑定</span>
                    </div>
                    <div style="margin-top: 24px;">
                        <span class="span-of-left">微信号</span>
                        <span v-if="userInfo.isBindWechat===1" v-text="userInfo.wechatNickname"
                              style="margin-left: 8px"></span><span
                        @click="bindWechat" class="gb-btn-a" v-if="userInfo.isBindWechat===0">绑定</span><span
                        class="gb-btn-a" v-if="userInfo.isBindWechat===1" @click="unbindWechat">解绑</span>
                    </div>
                    <div style="margin-top: 24px;" v-if="isAbroadIP">
                        <span class="span-of-left">Facebook</span>
                        <span v-if="userInfo.isBindFB===1" v-text="userInfo.fbName"
                              style="margin-left: 8px"></span><span
                        @click="bindFacebook" class="gb-btn-a" v-if="userInfo.isBindFB===0">绑定</span><span
                        class="gb-btn-a" v-if="userInfo.isBindFB===1" @click="unbindFacebook">解绑</span>
                    </div>
                    <div class="error-box" v-text="login2errorText">

                    </div>
                    <div class="btn-of-tj" @click="saveLogin2">
                        保存
                    </div>
                </div>
            </div>
        </modal>
        <modal name="bind1" :clickToClose="false" :width="600" height="auto" class="login-modal">
            <div class="title-of-tca">绑定手机号</div>
            <div class="tc tc-login1" style="padding-bottom: 94px">
                <div v-if="!hasNo" class="close" @click="backToLogin2"><span
                    class="iconfont iconshanchu-line1"></span>
                </div>
                <div style="text-align: center;margin-top: 40px"><input class="phone-input"
                                                                        v-model="phone"
                                                                        placeholder="手机号" /></div>
                <div class="yzm-div">
                    <input type="number" class="yzm" v-model="login1code" placeholder="验证码" maxlength="6" />
                    <span :class="this.codeSendBtnText === '发送验证码'?'yzm-btn':'yzm-btn-cant' " @click="codeSend(1, 2)"
                          v-text="codeSendBtnText"></span>
                </div>
                <div class="error-box" v-text="login1errorText">
                </div>
                <div :class="(checkCode.status&&checkPhone.status)?'can-click login-btn':'login-btn'"
                     @click="bindPhone()">
                    绑定
                </div>
                <div class="backToLocin-2" @click="backToLogin2">返回账号设置</div>
                <div id="afs_nc_container2" class="afs_nc_container afs_nc_container1" v-show="afsNCShow"></div>
            </div>
        </modal>
        <modal name="unbind1" :clickToClose="false" :width="600" height="auto" class="login-modal">
            <div class="title-of-tca">短信验证</div>
            <div class="tc tc-login1" style="padding-bottom: 94px">
                <div v-if="!hasNo" class="close" @click="backToLogin2"><span
                    class="iconfont iconshanchu-line1"></span>
                </div>
                <div style="text-align: center;margin-top: 40px"><input class="phone-input"
                                                                        v-model="phone"
                                                                        placeholder="手机号" /></div>
                <div class="yzm-div">
                    <input type="number" class="yzm" v-model="login1code" placeholder="验证码" maxlength="6" />
                    <span :class="this.codeSendBtnText === '发送验证码'?'yzm-btn':'yzm-btn-cant' " @click="codeSend(1, 3)"
                          v-text="codeSendBtnText"></span>
                </div>
                <div class="error-box" v-text="login1errorText">
                </div>
                <div :class="(checkCode.status&&checkPhone.status)?'can-click login-btn':'login-btn'"
                     @click="sendunbindWechat()">
                    解绑微信
                </div>
                <div class="backToLocin-2" @click="backToLogin2">返回账号设置</div>
                <div id="afs_nc_container3" class="afs_nc_container afs_nc_container1" v-show="afsNCShow"></div>
            </div>
        </modal>
        <modal name="unbind2" :clickToClose="false" :width="600" height="auto" class="login-modal">
            <div class="title-of-tca">短信验证</div>
            <div class="tc tc-login1" style="padding-bottom: 94px">
                <div v-if="!hasNo" class="close" @click="backToLogin2"><span
                    class="iconfont iconshanchu-line1"></span>
                </div>
                <div style="text-align: center;margin-top: 40px"><input class="phone-input"
                                                                        v-model="phone"
                                                                        placeholder="手机号" /></div>
                <div class="yzm-div">
                    <input type="number" class="yzm" v-model="login1code" placeholder="验证码" maxlength="6" />
                    <span :class="this.codeSendBtnText === '发送验证码'?'yzm-btn':'yzm-btn-cant' " @click="codeSend(1, 4)"
                          v-text="codeSendBtnText"></span>
                </div>
                <div class="error-box" v-text="login1errorText">
                </div>
                <div :class="(checkCode.status&&checkPhone.status)?'can-click login-btn':'login-btn'"
                     @click="sendunbindFacebook()">
                    解绑Facebook
                </div>
                <div class="backToLocin-2" @click="backToLogin2">返回账号设置</div>
                <div id="afs_nc_container4" class="afs_nc_container afs_nc_container1" v-show="afsNCShow"></div>
            </div>
        </modal>

        <alert-modal />
    </div>
</template>
<script>
import common from '../lib/common'
import validate from '../lib/validata'
import detectInfo from '../lib/detector'
import cropper from './cropper'
import alertModal from '../components/alertModal'

export default {
    components: {
        cropper,
        alertModal,
    },

    props: ['userInfo'],

    data() {
        return {
            login1Title: '登录后更精彩',
            login2Title: '账号设置',
            files: [],
            allAvatar: false,
            login1errorText: '',
            codeSendBtnText: '发送验证码',
            phone: '',
            login1code: '',
            nickname: '',
            login2errorText: '',
            desc: '',
            ossUrl: null,
            firstLogin: false,
            firstRandomAvatar: null,
            avatarList: [
                'https://static.chongyacdn.com/common/avator/default/beibei-1.png',
                'https://static.chongyacdn.com/common/avator/default/beibei-2.png',
                'https://static.chongyacdn.com/common/avator/default/beibei-3.png',
                'https://static.chongyacdn.com/common/avator/default/beibei-4.png',
                'https://static.chongyacdn.com/common/avator/default/beibei-5.png',
                'https://static.chongyacdn.com/common/avator/default/beibei-6.png',
                'https://static.chongyacdn.com/common/avator/default/beibei-7.png',
            ],
            prese: '',
            hasNo: false,
            wechatLogin: 'https://api.chongya.com/v1/wechat/oauth?equipment_type=pc',
            wechatBinding: 'https://api.chongya.com/v1/wechat/binding_wechat?equipment_type=pc',

            isAbroadIP: false, // 是否海外ip
            fromCreator: '',

            afsNCShow: false,
        }
    },

    created() {
        this.$tool.isAbroadIP().then((data) => {
            this.isAbroadIP = data
        })
    },

    mounted() {
        const { new_user, is_bind_wechat, is_bind_facebook } = common.parseQS()
        let hasProp = new_user
        if (hasProp === '1') {
            //新注册 需要弹窗
            this.$modal.show('login2')
        }
        let newUrl = this.removeParam("new_user", location.href);

        let hasbind = is_bind_wechat || is_bind_facebook
        if (hasbind === '1') {
            // this.$modal.show('login2')
            this.$modal.show('alert-modal', {
                data: {
                    title: '提示',
                    subtitle: is_bind_wechat === '1' ? '微信绑定成功' : (is_bind_facebook === '1' ? 'Facebook绑定成功' : '绑定成功'),
                    confirmText: '确定',
                }
            })
        }
        let newUrl2 = this.removeParam("is_bind_wechat", newUrl);
        newUrl2 = this.removeParam("is_bind_facebook", newUrl2);
        if (hasbind || hasProp) {
            window.history.replaceState({}, document.title, newUrl2);
        }
    },
    watch: {
        userInfo(ui) {
            this.nickname = ui.nickname
            this.desc = ui.intro
        }
    },
    methods: {
        removeParam(key, sourceURL) {
            var rtn = sourceURL.split("?")[0],
                param,
                params_arr = [],
                queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
            if (queryString !== "") {
                params_arr = queryString.split("&");
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split("=")[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                rtn = rtn + "?" + params_arr.join("&");
            }
            if (rtn[rtn.length - 1] === '?') {
                rtn = rtn.substr(0, rtn.length - 1)
            }
            return rtn;
        },
        showAllAvatar() {
            this.allAvatar = !this.allAvatar
        },
        backToLogin2() {
            this.phone = ''
            this.login1code = ''
            this.login1errorText = ''
            this.$modal.hide('bind1')
            this.$modal.hide('unbind1')
            this.$modal.hide('unbind2')
            this.$modal.show('login2')
        },
        codeSend(num, ctNum) {
            if (!this.checkPhone.status) {
                this.login1errorText = "请填写正确的手机号"
                return
            }
            this.login1errorText = ""

            if (this.codeSendBtnText !== '发送验证码') {
                return
            }

            if (!window.noCaptcha) return

            const afs_app_key = 'FFFF0N0N000000009811'
            const afs_scene = 'nc_message'
            const nc_token = [afs_app_key, (new Date()).getTime(), Math.random()].join(':');
            const NC_Opt = {
                renderTo: `#afs_nc_container${ctNum}`,
                appkey: afs_app_key,
                scene: afs_scene,
                token: nc_token,
                customWidth: 300,
                trans: { "key1": "code0" },
                elementID: ["usernameID"],
                is_Opt: 0,
                language: "cn",
                isEnabled: true,
                timeout: 3000,
                times: 5,
                apimap: {
                    // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
                    // 'get_captcha': '//b.com/get_captcha/ver3',
                    // 'get_captcha': '//pin3.aliyun.com/get_captcha/ver3'
                    // 'get_img': '//c.com/get_img',
                    // 'checkcode': '//d.com/captcha/checkcode.jsonp',
                    // 'umid_Url': '//e.com/security/umscript/3.2.1/um.js',
                    // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
                    // 'umid_serUrl': 'https://g.com/service/um.json'
                },
                callback: (data) => {
                    this.afsNCShow = false;
                    const afsOpts = {
                        afs_sig: data.sig,
                        afs_session_id: data.csessionid,
                        afs_token: nc_token,
                        afs_app_key,
                        afs_scene,
                    }

                    let sendPromise
                    if (num === 1) {
                        //绑定手机号
                        sendPromise = this.$api.accountSmsSendV2(this.phone, afsOpts)
                    } else {
                        //正常登陆
                        sendPromise = this.$api.sendsmscaptchaV2(this.phone, afsOpts)
                    }

                    sendPromise.then((data) => {
                        this.$toast('验证码已发送到您的手机')
                        this.codeSendBtnText = 120
                        this.timeOut()
                    }).catch((err) => {
                        this.$toast(err.errmsg || '操作失败')
                    })
                },
            }
            const nc = new window.noCaptcha(NC_Opt)
            nc.upLang('cn', {
                _startTEXT: "请按住滑块，拖动到最右边",
                _yesTEXT: "验证通过",
                _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
            })
            this.afsNCShow = true;
        },
        bindMobile() {
            this.login1errorText = ''
            this.$modal.hide('login2')
            this.$modal.show('bind1')
        },
        unbindWechat() {
            if (this.userInfo.isBindMobile !== 1) {
                this.$toast('必须绑定手机号后才能解绑微信')
                return
            }
            this.$modal.hide('login2')
            this.$modal.show('unbind1')
        },
        sendunbindWechat() {
            if (this.checkCode.status && this.checkPhone.status) {
                this.$api.unbindWechat({ mobile: this.phone, captcha: this.login1code, detector: JSON.stringify(detectInfo) }).then(({ data }) => {
                    this.$emit('flash')
                    this.backToLogin2()
                }).catch((err) => {
                    this.login1errorText = err.errmsg
                })
            }
        },
        timeOut() {
            setTimeout(() => {
                if (this.codeSendBtnText === 0) {
                    this.codeSendBtnText = '发送验证码'
                } else if (this.codeSendBtnText !== '发送验证码') {
                    this.codeSendBtnText--
                    this.timeOut()
                }
            }, 1000)
        },
        bindWechat() {
            window.location.href = this.wechatBinding + '&detector=' + encodeURIComponent(JSON.stringify(detectInfo))
        },
        goLogin() {
            if (this.checkCode.status && this.checkPhone.status) {
                this.$api.login({ mobile: this.phone, captcha: this.login1code, detector: JSON.stringify(detectInfo), from_creator: this.fromCreator, promo_code: this.getPromoCode() }).then(({ data }) => {
                    if (data) {
                        location.href = `${location.href}${location.href.indexOf('?') > -1 ? '&' : '?'}new_user=1`
                    } else {
                        location.reload()
                    }
                }).catch((err) => {
                    this.login1errorText = err.errmsg
                })
            }
        },
        getPromoCode() {
            let promoCode = ''
            if (window.localStorage && window.localStorage.getItem) {
                promoCode = window.localStorage.getItem('promo-code') || ''
            }
            return promoCode
        },
        bindPhone() {
            if (this.checkCode.status && this.checkPhone.status) {
                this.$api.bindMobile({ mobile: this.phone, captcha: this.login1code, detector: JSON.stringify(detectInfo) }).then(({ data }) => {
                    this.$emit('flash')
                    this.backToLogin2()
                }).catch((err) => {
                    this.login1errorText = err.errmsg
                })
            }
        },
        goWechat() {
            window.location.href = `${this.wechatLogin}&detector=${encodeURIComponent(JSON.stringify(detectInfo))}&from_creator=${encodeURIComponent(this.fromCreator)}&promo_code=${this.getPromoCode()}`
        },

        inputFile(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                this.$nextTick(function() {
                    this.$refs.uploadavatar.active = true
                })
            }
            if (newFile && oldFile) {
                // 更新文件
                if (newFile.success !== oldFile.success) {   // 上传成功
                    if (newFile.response.code === 200) {  // 判断后台返回的code值
                        // 上传成功
                        // console.log(100)
                    } else {
                        // 上传失败
                        // console.log(200)
                    }
                }
            }
        },
        saveLogin2() {
            //保存
            // nickname validata
            if (!this.checkNickName.status) {
                this.login2errorText = this.checkNickName.errmsg
                return
            }

            if (!this.checkdesc.status) {
                this.login2errorText = this.checkdesc.errmsg
                return
            }
            this.$api.saveUserProfile(this.nickname, this.avatarUrl, this.desc).then((data) => {
                location.reload()
            }).catch((err) => {
                this.login2errorText = err.errmsg
            })
        },
        changeNickname() {
            this.nickname = common.randomNickname()
        },
        cropperIn(d) {
            this.ossUrl = d.oss_url
        },
        changeAvatar(avatar) {
            this.ossUrl = avatar
        },
        hideLogin2() {
            this.$modal.hide('login2')
            if (this.firstRandomAvatar) {
                window.location.reload()
            }
        },
        beforeOpen(event) {
            try {
                this.prese = event.params.prese
                this.login2Title = event.params.title || '账号设置'
            } catch (e) {
                this.prese = ''
                this.login2Title = '账号设置'
            }
        },
        beforeOpen1(event) {
            try {
                this.hasNo = event.params.hasNo || false
                this.login1Title = event.params.title || '登录后更精彩'
                this.fromCreator = event.params.fromCreator || ''
            } catch (e) {
                this.hasNo = false
                this.login1Title = '登录后更精彩'
                this.fromCreator = ''
            }
        },


        // facebook
        goFacebook() {
            // facebook oauth 回调不要加任何回调参数，参数无 oauth 回调白名单，登录会失败
            // let redirectUri = 'https://connectfb.chongya.com/v1/account/facebook/oauth_callback?return_url=' + encodeURIComponent(window.location.href)
            // let redirectUri = 'https://connectfb.chongya.com/v1/account/facebook/oauth_callback?detector=' + encodeURIComponent(JSON.stringify(detectInfo)) + '&from_creator=' + encodeURIComponent(this.fromCreator)
            let redirectUri = 'https://connectfb.chongya.com/v1/account/facebook/oauth_callback'
            window.location.href = 'https://www.facebook.com/dialog/oauth?client_id=470066467181918&code=acgon&redirect_uri=' + encodeURIComponent(redirectUri)
        },
        bindFacebook() {
            // facebook oauth 回调不要加任何回调参数，参数无 oauth 回调白名单，登录会失败
            // let redirectUri = 'https://connectfb.chongya.com/v1/account/facebook/bind_callback?detector=' + encodeURIComponent(JSON.stringify(detectInfo))
            let redirectUri = 'https://connectfb.chongya.com/v1/account/facebook/bind_callback'
            window.location.href = 'https://www.facebook.com/dialog/oauth?client_id=470066467181918&code=acgon&redirect_uri=' + encodeURIComponent(redirectUri)
        },
        unbindFacebook() {
            if (this.userInfo.isBindMobile !== 1) {
                this.$toast('必须绑定手机号后才能解绑Facebook')
                return
            }
            this.$modal.hide('login2')
            this.$modal.show('unbind2')
        },
        sendunbindFacebook() {
            if (this.checkCode.status && this.checkPhone.status) {
                this.$api.unbindFacebook({ mobile: this.phone, captcha: this.login1code, detector: JSON.stringify(detectInfo) }).then(({ data }) => {
                    this.$emit('flash')
                    this.backToLogin2()
                }).catch((err) => {
                    this.login1errorText = err.errmsg
                })
            }
        },

    },
    computed: {
        checkPhone() {
            return validate.isPhone(this.phone)
        },
        checkCode() {
            return validate.isCode(this.login1code)
        },
        checkNickName() {
            return validate.isNickName(this.nickname)
        },
        checkdesc() {
            return validate.isDesc(this.desc)
        },
        avatarUrl() {
            if (this.ossUrl) {
                return this.ossUrl
            } else {
                if (this.userInfo && this.userInfo.avator) {
                    return this.userInfo.avator
                }
                if (this.firstRandomAvatar) {
                    return this.firstRandomAvatar
                }
                return 'https://static.chongyacdn.com/common/avator/default/beibei-1.png'
            }
        },
        errorClasses() {
            const errorShadow = 'primary-shadow-error'
            const errorBorder = 'primary-border-error'
            const g = (condition, klass) => condition ? klass : ''
            const { login1errorText, login2errorText } = this
            return {
                phone: g(!this.checkPhone.status && login1errorText, errorShadow),
                code: g(this.login1errorText === '验证码错误', errorShadow),
                nickName: g(!this.checkNickName.status && login2errorText, errorBorder),
                desc: g(!this.checkdesc.status && login2errorText, errorBorder),
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../lib/general";
@import "../lib/tc";

.login-modal .tc-login1 {
    overflow: hidden;
    width: auto;
    padding-top: 72px;
    padding-bottom: 72px;

    .dlgjc-span {
        text-align: center;
        font-size: 24px;
        color: #333333;
    }

    .phone-input {
        display: inline-block;
        width: 340px;
        height: 48px;
        background: #F7F7F7;
        border-radius: 24px;
        border: 0;
        padding: 0;
        outline: 0;
        font-size: 16px;
        padding-left: 20px;
        box-sizing: border-box;
    }

    .yzm {
        width: 220px;
        height: 48px;
        border-radius: 24px;
        background: #F7F7F7;
        border: 0;
        padding: 0;
        font-size: 16px;
        outline: 0;
        vertical-align: top;
        box-sizing: border-box;
        padding-left: 20px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &[type="number"] {
            -moz-appearance: textfield;
        }
    }

    .yzm-btn {
        .btn-color-normal();
        width: 112px;
        height: 48px;
        border-radius: 24px;
        background-color: #FCDC2C;
        text-align: center;
        vertical-align: middle;
        line-height: 48px;
        cursor: pointer;
        font-size: 16px;
        color: #000000;
        display: inline-block;
        margin-left: 8px;
    }

    .yzm-btn-cant {
        .btn-color-inactive();
        width: 112px;
        height: 48px;
        border-radius: 24px;
        background-color: #ECECEC;
        text-align: center;
        vertical-align: middle;
        line-height: 48px;
        cursor: pointer;
        font-size: 16px;
        color: #999999;
        display: inline-block;
        margin-left: 8px;
    }

    .yzm-div {
        text-align: center;
        margin-top: 16px;
    }

    .yhxy {
        margin-top: 24px;
        text-align: center;
    }

    .textof {
        color: #bbb;

        a {
            .link-color-blue();
            color: #57BCE1;
            cursor: pointer;
        }
    }

    .login-btn {
        .btn-color-gray2();
        width: 340px;
        height: 48px;
        margin: 0 auto;
        .middle-all();
        background: #ECECEC;
        border-radius: 24px;
        font-size: 22px;
        color: #999999;
        cursor: pointer;

        &:hover {
            background: #ECECEC;
        }
    }

    .can-click {
        .btn-color-normal();
        background-color: #FCDC2C;
        color: #000;

        &:hover {
            color: #000;
        }
    }
}

.tc-login2 {
    .text-title {
        text-align: center;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
        margin-top: 40px;
    }

    .text-content {
        color: #666666;
        text-align: center;
        margin-top: 16px;
    }

    .box-all-cont {
        padding-top: 40px;

        .span-of-left {
            display: inline-block;
            width: 84px;
            color: #333333;
            vertical-align: top;
            text-align: right;
        }

        .nickname-input {
            margin-left: 8px;
            width: 200px;
            height: 32px;
            background: #F7F7F7;
            border: 1px solid #DBDBDB;
            border-radius: 8px;
            display: inline-block;
            box-sizing: border-box;
            padding-left: 10px;
            outline: 0;
        }

        .exchange-span {
            color: #666;
            margin-left: 8px;
            cursor: pointer;
            user-select: none;
        }

        .not-line {
            display: inline-block;
            float: left;
            padding-top: 35px;
        }

        .user-avatar-over {
            margin-left: 12px;
            float: left;
            overflow: hidden;

            .default-avatar {
                width: 100px;
                height: 100px;
                border-radius: 8px;
                overflow: hidden;
                float: left;
            }

            .upload-avatar {
                display: inline-block;
                margin-left: 8px;
                margin-top: 30px;

                &:hover .upload-box-btn {
                    background-color: #ffcf00;
                }
            }

            .note {
                position: absolute;
                left: 300px;
                top: 242px;
                font-size: 12px;
                color: #999;
            }

            .upload-box-btn {
                .btn-color-normal();
                background-color: #FCDC2C;
                width: 88px;
                height: 32px;
                border-radius: 16px;
                float: left;
                .middle-all();
                color: #000;
                cursor: pointer;
            }
        }

        .list-of-default-avatar {
            flex-wrap: wrap;
            width: 484px;
            overflow: hidden;
            margin-top: 16px;
            display: flex;

            .item-of-ava {
                overflow: hidden;
                margin-right: 8px;
                margin-bottom: 8px;
                width: 48px;
                height: 48px;
                border: 2px solid transparent;
                border-radius: 5px;
                cursor: pointer;

                > img {
                    width: 100%;
                }
            }

            .click-ava {
                border: 2px solid #f37c07;
            }
        }

        .qyms-div {
            margin-top: 24px;
            vertical-align: top;

            textarea {
                display: inline-block;
                background: #F7F7F7;
                border: 1px solid #DBDBDB;
                border-radius: 8px;
                width: 484px;
                height: 113px;
                resize: none;
                outline: 0;
                padding: 10px;
                margin-left: 8px;
                box-sizing: border-box;
            }
        }

        .btn-of-tj {
            .btn-color-normal();
            background: #FCDC2C;
            border-radius: 20px;
            width: 112px;
            height: 40px;
            margin: 0 auto;
            margin-bottom: 36px;
            font-size: 16px;
            color: #000000;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}

.error-box {
    color: #E96A55;
    .middle-all();
    padding-top: 10px;
    padding-bottom: 10px;
    height: 36px;
}

.text-thrid-te {
    font-size: 14px;
    color: #999;
    text-align: center;
    margin-top: 66px;
}

.text-thrid-te-div {
    margin-top: 24px;
    text-align: center;

    span {
        margin-right: 16px;
        font-size: 45px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
    }
}

.phone-of-this {
    padding-top: 2px;
    display: inline-block;
}

.gb-btn-a {
    .link-color-blue();
    margin-left: 8px;
}

.title-of-tca {
    position: absolute;
    padding: 24px;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: 600;
}

.backToLocin-2 {
    color: #a0a0a0;
    position: absolute;
    bottom: 40px;
    right: 24px;
    cursor: pointer;
    .link-color-gray1();
}

.afs_nc_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -180px;
    margin-top: -115px;
    width: 360px;
    height: 132px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.afs_nc_container1 {
    margin-top: -100px;
}
</style>
