<template>
    <div class="tc alert-tc">
        <modal name="alert-modal" :clickToClose="false" :width="600" height="auto" @before-open="beforeOpen">
            <div style="padding: 24px">
                <div class="title" v-if="config.title" v-text="config.title"></div>
                <div class="close" @click="$modal.hide('alert-modal')">
                    <span class="iconfont iconshanchu-line1"></span>
                </div>
                <div class="subtitle" v-if="config.subtitle" v-text="config.subtitle"></div>
                <div class="msg-of-tc-box" v-if="config.content" v-html="config.content"></div>
                <div class="btn-box">
                    <span class="confirm-btn" v-text="config.confirmText" @click="isConfirm"></span>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    name: 'alert-modal',
    data() {
        return {
            config: {
                title: '',
                subtitle: '',
                content: '',
                confirmText: '',
            },
            confirmFun: null,
        }
    },
    methods: {
        beforeOpen(event) {
            this.config = event.params.data
            this.confirmFun = event.params.confirmFun || null
        },
        isConfirm() {
            if (Object.prototype.toString.call(this.confirmFun) === '[object Function]') {
                this.confirmFun()
            }
            this.$modal.hide('alert-modal')
        }
    }
}
</script>

<style lang="less">
@import "../lib/general";
@import "../lib/tc";

.alert-tc {
    .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
        margin-top: 40px;
        text-align: center;
    }

    .msg-of-tc-box {
        margin-top: 12px;
        padding: 0 66px;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
    }

    .confirm-btn {
        .btn-color-normal();
        width: 312px;
        height: 40px;
        border-radius: 20px;
        display: inline-block;
        background-color: #fcdc2c;
        color: #000;
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        vertical-align: middle;
        cursor: pointer;
    }

    .btn-box {
        margin-top: 36px;
        text-align: center;
        margin-bottom: 16px;
    }
}
</style>