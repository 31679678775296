export default {
    isPhone(phone) {
        if (!(/^1[23456789]\d{9}$/.test(phone))) {
            return { status: false }
        } else {
            return { status: true }
        }
    },
    isCode(code) {
        if (code.length > 6) {
            return { status: false, errmsg: '验证码不能超过6位' }
        }
        if (!(/^[0-9]{6}$/).test(code)) {
            return { status: false }
        }
        return { status: true }
    },
    //用户昵称校验
    isNickName(code) {
        if (getLength(code) > 24) {
            return { status: false, errmsg: '昵称过长，最多不超过8个字' }
        }
        if (code.length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写昵称' }
        }

    },
    isDesc(desc) {
        if (getLength(desc) > 300) {
            return { status: false, errmsg: '简介过长，最多不超过100个字' }
        }
        return { status: true }
    },
    //成为创作者 主题
    isCreatorSubject(text) {
        if (getLength(text) > 48) {
            return { status: false, errmsg: '创作主题过长，最多不超过16个字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写创作主题' }
        }
    },
    //成为创作者 详细介绍
    isCreatorIntro(text) {
        if (getLength(text) > 3000) {
            return { status: false, errmsg: '简介过长，最多不超过1000个字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写详细介绍' }
        }
    },
    //成为创作者 标签
    isCreatorTag(text) {
        if (text.length > 20) {
            return { status: false, errmsg: '标签过长，最多不超过20个字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写标签' }
        }
    },
    //成为创作者 主页地址
    isCreatorUri(text) {
        if (!(/^[a-z][a-z0-9]{2,19}$/).test(text)) {
            return { status: false, errmsg: '主页地址格式有误。3～20个字符，仅能包含小写字母和数字，并以小写字母开头' }
        } else {
            return { status: true }
        }
    },
    isPublishTitle(text) {
        if (getLength(text) > 120) {
            return { status: false, errmsg: '标题不能超过40个汉字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写标题' }
        }
    },
    isPublishContent(text) {
        if (getLength(text) > 60000) {
            return { status: false, errmsg: '描述过长' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写描述' }
        }
    },
    isArticleRichContent(text) {
        if (getLength(text) > 600000) {
            return { status: false, errmsg: '正文过长' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写正文' }
        }
    },
    //创建订阅等级  等级名称
    isSubLevelName(text) {
        if (getLength(text) > 24) {
            return { status: false, errmsg: '等级名称过长，最多不超过8个字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写等级名称' }
        }
    },
    //创建订阅等级  等级描述
    isSubLevelIntro(text) {
        if (getLength(text) > 600) {
            return { status: false, errmsg: '描述过长，最多不超过200个字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写描述' }
        }
    },
    isSubLevelCost(text) {
        if (!(/^[0-9]{0,6}$/).test(text) || text === '') {
            return { status: false, errmsg: '订阅金额填写错误' }
        } else {
            return { status: true }
        }
    },
    isArtComment(text) {
        if (text.trim().length === 0) {
            return { status: false, errmsg: '请填写回复' }
        } else if (getLength(text) > 1500) {
            return { status: false, errmsg: '回复过长，最多不超过500个字' }
        }
        return { status: true }
    },
    isCreatorReplyComment(text) {
        if (getLength(text) > 1500) {
            return { status: false, errmsg: '回复过长，最多不超过500个字' }
        }
        if (text.trim().length > 0) {
            return { status: true }
        } else {
            return { status: false, errmsg: '请填写回复' }
        }
    },
    isSupportComment(text) {
        if (getLength(text) > 900) {
            return { status: false, errmsg: '留言过长，最多不超过300个字' }
        } else {
            return { status: true }
        }
    },
    isArticleBrief(text) {
        if (getLength(text) > 233 * 3) {
            return { status: false, errmsg: '简介过长，最多不超过233个字' }
        } else {
            return { status: true }
        }
    },

    isAlbumRecText(text) {
        if (getLength(text) > 240) {
            return { status: false, errmsg: '推荐语不能超过80字' }
        } else {
            return { status: true }
        }
    },

    isAlbumContent(text) {
        if (getLength(text) > 60000) {
            return { status: false, errmsg: '正文过长' }
        } else {
            return { status: true }
        }
    },

    isComment(text) {
        if (text.trim().length === 0) {
            return { status: false, errmsg: '请填写回复' }
        } else if (getLength(text) > 1500) {
            return { status: false, errmsg: '回复过长，最多不超过500个字' }
        }
        return { status: true }
    },

    isCheckinContent(text) {
        if (text.trim().length > 0) {
            if (getLength(text) > 900) {
                return { status: false, errmsg: '打卡内容，最多不超过300个字' }
            }
        }
        return { status: true }
    },

    isCashoutName(text) {
        if (text.trim().length > 0) {
            if (getLength(text) > 90) {
                return { status: false, errmsg: '提现姓名，最多不超过30个字' }
            }
            return { status: true }
        }
        return { status: false, errmsg: '请输入真实姓名' }
    },

    isCashoutAccount(text) {
        if (text.trim().length > 0) {
            if (!(/^[1-9]\d{9,29}$/.test(text))) {
                return { status: false, errmsg: '银行卡号格式错误' }
            }
            if (getLength(text) > 300) {
                return { status: false, errmsg: '银行卡号最多不超过100个字' }
            }
            return { status: true }
        }
        return { status: false, errmsg: '请输入银行卡号' }
    },

    isCashoutIdnumber(text) {
        if (text.trim().length > 0) {
            if (!(/^(\d{15}|\d{17}(\d|X))$/.test(text))) {
                return { status: false, errmsg: '身份证号格式错误' }
            }
            if (text.length > 100) {
                return { status: false, errmsg: '身份证号最多不超过100个字' }
            }
            return { status: true }
        }
        return { status: false, errmsg: '请输入身份证号' }
    },

    isCashoutPhone(text) {
        if (text.trim().length > 0) {
            if (!(/^1[23456789]\d{9}$/.test(text))) {
                return { status: false, errmsg: '手机号格式错误' }
            }
            if (text.length > 30) {
                return { status: false, errmsg: '手机号最多不超过30个字' }
            }
            return { status: true }
        }
        return { status: false, errmsg: '请输入手机号' }
    },

    isCashoutKaihuhang(text) {
        if (text.trim().length > 0) {
            if (getLength(text) > 300) {
                return { status: false, errmsg: '开户银行最多不超过100个字' }
            }
            return { status: true }
        }
        return { status: false, errmsg: '请输入开户银行' }
    },

    isTagsContent(text) {
        if (text.length > 20) {
            return { status: false, errmsg: '标签过长，最多不超过20个字' }
        }
        return { status: true }
    },

}

function getLength(str = '') {
    var realLength = 0, len = str.length, charCode = -1;
    for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) realLength += 1;
        else realLength += 3;
    }
    return realLength;
};
