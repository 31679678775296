<template>
    <div class="footer">
        <div class="footx">
            <div class="bh">
                <span v-html="`&copy;2019-${copyrightYear} 冲呀——支持你喜爱的创作者！`"></span>
                <div class="foot-links">
                    <a class="btn-a" :href="`${baseHostname}/about`">关于冲呀</a>
                    <a class="btn-a" :href="`${baseHostname}/product`">产品介绍</a>
                    <a class="btn-a" :href="`${baseHostname}/terms`">用户协议</a>
                    <a class="btn-a" :href="`${baseHostname}/privacy`">隐私协议</a>
                    <a class="btn-a" :href="`${baseHostname}/help`">帮助中心</a>
                    <a class="btn-a" :href="`${baseHostname}/contact`">联系我们</a>
                    <a class="btn-a" @click="goServiceChat">问题反馈</a>
                </div>
            </div>
            <div class="foot-bottom">
                <div class="ba">
                    <p><span>增值电信业务经营许可证 京B2-20200071</span></p>
                    <p><span>网络文化经营许可证 京网文[2019]4886-533号</span></p>
                    <p><a href="http://www.beian.miit.gov.cn" target="_blank">京ICP备19026435号</a></p>
                    <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502038897" target="_blank"><img src="./beian.png">京公网安备11010502038897号</a></p>
                </div>
                <div class="download">
                    <div class="qrcode-wrap">
                        <a :href="`${baseHostname}/app`">
                            <img src="https://static.chongyacdn.com/app/dl_app_client_qrcode.png" alt="客户端下载二维码">
                            <span>下载冲呀 App</span>
                        </a>
                    </div>
                    <img src="./man.png" class="man">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    'baseHostname': {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      copyrightYear: new Date().getFullYear(),
    }
  },

  methods: {
    goServiceChat() {
      if (window._user_info) {
        window._user_info.then(() => {
          location.href = `${this.baseHostname}/messages?id=${this.$tool.ONLINE_SERVICE_CREADER_UID}`
        }).catch(() => {
          this.$modal.show('login1', { title: '登录后才能反馈问题呀' })
        })
      } else {
        location.href = `${this.baseHostname}/messages?id=${this.$tool.ONLINE_SERVICE_CREADER_UID}`
      }
    },
  },

}
</script>

<style lang="less">
@import url("../../lib/general");

.footer {
    box-sizing: border-box;
    min-width: 1056px;
    width: 100%;
    height: 181px;
    padding: 20px 0;
    background: #FAFAFA;

    .footx {
        position: relative;
        width: 1056px;
        margin: 0 auto;
    }

    .bh {
        font-size: 14px;
        color: #333;
        letter-spacing: 0.93px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .foot-links {
            .btn-a {
                margin-left: 8px;
                color: #888;
                cursor: pointer;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    .foot-bottom {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ba {
        color: #777;

        > p {
            margin-bottom: 8px;
            font-size: 14px;
            line-height: 1;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                .link-color-gray1();
                font-size: 14px;
                letter-spacing: 0.93px;

                img {
                    position: relative;
                    top: -2px;
                    margin-right: 3px;
                }
            }
        }
    }

    .download {
        position: relative;

        .qrcode-wrap {
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
            width: 96px;
            height: 112px;
            padding: 8px;
            border-radius: 8px 8px 4px;
            background-color: #f0f0f0;

            img {
                width: 80px;
                height: 80px;
            }

            span {
                font-size: 12px;
                color: #333;
                line-height: 1.33;
            }
        }

        .man {
            margin-top: 31px;
            margin-left: 80px;
            width: 69px;
            height: 80px;
        }
    }
}

</style>
