<template>
    <div>
        <modal name="share" :clickToClose="true" :width="394" height="auto" @before-open="beforeOpen" @opened="opened" class="share-modal">
            <div style="padding: 18px 24px">
                <div class="title">{{baseTitle||''}}</div>
                <div class="close" @click="$modal.hide('share')">
                    <i class="iconfont iconshanchu-line1"> </i>
                </div>
                <div class="qrcode-box">
                    <div class="qrcode" ref="shareQrcode"></div>
                </div>
                <p class="tips"><i class="icon iconfont iconweixin-block"></i>扫一扫分享至微信</p>
                <div class="share-others">
                    <p>或分享至其他平台</p>
                    <ul>
                        <li @click="shareVb">
                            <img src="../lib/img/vb.png" alt="" width="100%" height="100%">
                            <span>微博</span>
                        </li>
                        <li @click="shareQQ">
                            <img src="../lib/img/qq.png" alt="" width="100%" height="100%">
                            <span>QQ</span>
                        </li>
                        <li @click="shareSpace">
                            <img src="../lib/img/qq_space.png" alt="" width="100%" height="100%">
                            <span>空间</span>
                        </li>
                        <li @click="copyLink" :data-clipboard-text="copyValue" ref="clipboard" class="copyLink">
                            <img src="../lib/img/link.png" alt="" width="100%" height="100%">
                            <span>复制链接</span>
                        </li>
                    </ul>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard';

export default {
    name: "share",
    data() {
        return {
            url: '',
            qzoneurl: '', // 分享至qzone专用url
            copyValue: '',
            title: '',
            baseTitle: '',
            img: ''
        }
    },
    methods: {
        created() {
        },
        beforeOpen(event) {
            this.url = event.params.url
            this.qzoneurl = event.params.qzoneurl || this.url
            this.copyValue = event.params.title + ' ' + event.params.url
            this.title = event.params.title
            this.baseTitle = event.params.baseTitle
            this.img = event.params.img
        },
        opened() {
            let that = this
            new QRCode(that.$refs.shareQrcode, {
                text: this.url,
                width: 160,
                height: 160,
            })
            var clipboard = new Clipboard(that.$refs.clipboard);
            clipboard.on('success', function(e) {
                that.$toast('复制成功')
                e.clearSelection();
            });

            clipboard.on('error', function(e) {
                that.$toast('复制失败，请手动复制')
            });
        },
        copyLink() {

        },
        shareSpace() {
            window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(this.qzoneurl)}&sharesource=qzone&title=${encodeURIComponent(this.title)}&pics=${encodeURIComponent(this.img)}&summary=`, '_blank')
        },
        shareQQ() {
            window.open(`https://connect.qq.com/widget/shareqq/index.html?url=${encodeURIComponent(this.url)}&sharesource=qzone&title=${encodeURIComponent(this.title)}`, '_blank')
        },
        shareVb() {
            window.open(`http://v.t.sina.com.cn/share/share.php?title=${encodeURIComponent(this.title + " @冲呀CHONGYA #冲呀创作平台#")}&url=${encodeURIComponent(this.url)}&pic=${encodeURIComponent(this.img)}`, '_blank')
        }
    }
}
</script>

<style lang="less">
@import "../lib/general";
@import "../lib/tc";

.share-modal {
    ul, li, ol {
        list-style: none;
    }

    .v--modal > div .title {
        font-size: 18px;
        color: #333;
        line-height: 1.56;
    }

    .tips {
        padding: 24px 0;
        font-size: 14px;
        line-height: 20px;
        color: #333;
        text-align: center;

        i {
            top: 2px;
            margin-right: 8px !important;
            font-size: 28px !important;
            color: #09BB07;
            vertical-align: -3px;
        }
    }

    .mt24 {
        margin-top: 24px;
    }

    .qrcode-box {
        width: 160px;
        height: 160px;
        margin: 40px auto 0;
    }

    .share-others {
        margin-top: 10px;
        margin-bottom: 12px;
        font-size: 14px;
        color: #A0A0A0;

        ul {
            display: flex;
            justify-content: space-around;
            padding-top: 12px;

            li {
                .link-color-gray1();
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                > img {
                    margin-bottom: 10px;
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
}
</style>
